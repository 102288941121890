import React, { Component } from "react";
import HookAPI from "../../utility/HookAPI";
import * as methodTypes from "../../Constants/method.constants";

export default class SubmitEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }

    handleChange = (e) => {
        var changeState = e.target.name;
        this.setState({
            [changeState]: e.target.value
        })
    }

    handleSubmit = (e) => {
        const { email } = this.state;
        let dataPrepare = Object();
        HookAPI(dataPrepare, "users/forgotpassword/"+email, methodTypes.GET);
    }

    render() {
            return (
                <form>
                    <h3>Forgot Password</h3>

                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" className="form-control" placeholder="Enter email" name="email" onChange={this.handleChange.bind(this)} />
                    </div>

                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    <div style={{display:"inline-block"}}>
                    </div>

                </form>
            );


    }
}