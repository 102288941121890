import React, { Component } from 'react';
import Navbar from "../Navbar";
import {
    Container, Row, Table, List, ListInlineItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import HookAPI from "../../utility/HookAPI";
import * as methodTypes from '../../Constants/method.constants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid,
    width: 250
});

class ListSharedData extends Component {
    constructor(props) {
        super();
        this.state = {
            listSharedUser: []
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }




    componentDidMount() {
        HookAPI(null, "datasharing/{userid}/list").then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    listSharedUser: response.data.data
                })
            }
        })
    }

    handleClickDelete = (id) => {
        const { listSharedUser } = this.state;
        let confirmRemove = window.confirm('Confirm Delete');
        if (confirmRemove) {
            HookAPI(null, "datasharing/{userid}/delete/" + id, methodTypes.DELETE).then((response) => {
                if (response.data.status === 200) {
                    let deleteIndex = listSharedUser.findIndex(element => element.id === id);
                    listSharedUser.splice(deleteIndex, 1);

                    this.setState({
                        listSharedUser: listSharedUser
                    })
                }
            })
        }

    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const { listSharedUser } = this.state;

        const currPos = result.source.index;
        const newPos = result.destination.index;

        var list = listSharedUser;

        const currData = listSharedUser[currPos];
        const newData = listSharedUser[newPos];
        list[currPos] = newData;
        list[newPos] = currData;
        this.setState({
            listSharedUser: list
        })

        // const items = reorder(
        //   this.state.items,
        //   result.source.index,
        //   result.destination.index
        // );

        // this.setState({
        //   items
        // });
        let prepareData = [];
        list.forEach(el => {
            prepareData.push(el.id);
        })
        HookAPI(prepareData,"datasharing/{userid}/edit",methodTypes.POST);
    }


    render() {
        const { listSharedUser } = this.state;
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            List of Share Account
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab">
                            <Table className="table" responsive>
                                <thead>
                                    <tr>
                                        <th>Share for</th>
                                        <th>Data receive</th>
                                        <th>Share project Id</th>
                                        <th>Role</th>
                                        <th>Share in minute</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">

                                        {(provided, snapshot) => (
                                            <tbody {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}>

                                                {
                                                    listSharedUser.length > 0 ?
                                                        (
                                                            listSharedUser.map((element, index) => (
                                                                <Draggable key={index} draggableId={element.id + '-' + index} index={index}>

                                                                    {(provided, snapshot) => (
                                                                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                            <td>{element.sharedUser.name} - {element.sharedUser.email}</td>
                                                                            <td>{element.numberOfData}</td>

                                                                            <th>{element.projectId}</th>
                                                                            <td>{element.role}</td>
                                                                            <td>{element.role}</td>
                                                                            <td>
                                                                                <Container fluid={true}>
                                                                                    <Row>
                                                                                        <List type="inline">
                                                                                            <ListInlineItem><Link to={`/DataSharing?project=${element.projectId}`}>Edit</Link></ListInlineItem>
                                                                                            {/* <ListInlineItem><Link to={"/"}>Detail</Link></ListInlineItem> */}| &nbsp;
                                                                                            <ListInlineItem><Link to={"#"} onClick={(e) => this.handleClickDelete(element.id)}>Remove</Link></ListInlineItem>
                                                                                        </List>
                                                                                    </Row>
                                                                                </Container>
                                                                            </td>
                                                                        </tr>
                                                                    )}


                                                                </Draggable>

                                                            ))
                                                        ) : (
                                                            <tr><td colSpan={5}>Nothing to show!</td></tr>
                                                        )

                                                }


                                            </tbody>
                                        )}


                                    </Droppable>
                                </DragDropContext>

                            </Table>

                            {/* {
                                listSharedUser.length > 0 ?
                                    listSharedUser.map(element => (
                                        <div key={element.id} className="card-hover" style={{ display: "block" }}>
                                            <Container>
                                                <Row>
                                                    <h6 className="card-title component-header">{element.sharedUser.name} - {element.sharedUser.email}</h6>
                                                </Row>
                                                <Row>
                                                    <h6>amount of data received: {element.numberOfData}</h6>
                                                </Row>
                                                <Row>
                                                    <h6>role: {element.role}</h6>
                                                </Row>
                                                <Row style={{ justifyContent: "space-between" }}>
                                                    <a href={`./EditDataSharing/${element.id}`}>
                                                        <button className="button-editLSL btn-sm">
                                                            <span><span className="ion-ios-create"></span> &nbsp;Edit</span>
                                                        </button>
                                                    </a>
                                                    <button className="button-deleteLSL btn-sm" onClick={this.handleClickDelete.bind(this, element.id)}>
                                                        <span><span className="ion-md-close"></span> &nbsp;Delete</span>
                                                    </button>
                                                </Row>
                                            </Container>


                                            <hr />
                                        </div>
                                    )) : (<h3>Not thing to show</h3>)
                            } */}
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default ListSharedData;