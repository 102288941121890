import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import * as methodTypes from "../../Constants/method.constants";
import HookAPI from '../../utility/HookAPI';
import Navbar from '../Navbar';
import { Col, FormGroup, Label, Input, Form, Button, CardFooter, Spinner } from 'reactstrap';


const UserShareMessageRow = (props) => {
    const [data] = useState(props.data);
    return (

        <Form className="shadow p-3 bg-body rounded">
            <FormGroup row>
                <Label for={`exampleName${data.id}`} sm={5} >Name</Label>
                <Col sm={7}>
                    <Input type="text" readOnly defaultValue={data.name} id={`exampleName${data.id}`} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for={`exampleEmail${data.id}`} sm={5} >Email</Label>
                <Col sm={7}>
                    <Input type="text" readOnly defaultValue={data.email} id={`exampleEmail${data.id}`} />
                </Col>
            </FormGroup>
            <Button className="float-right" onClick={() => props.remove(data.id)} color={`danger`} size="sm">Remove</Button>
        </Form>
    )
}


const PageManageShare = (props) => {
    const wrapperRefUser = useRef();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [userPanelLoaded, setUserPanelLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [createList, setCreateList] = useState([]);
    const { id } = useParams();
    const [showUserPanel, setShowUserPanel] = useState(false);
    const [pageManage, setPageManage] = useState(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRefUser.current && !wrapperRefUser.current.contains(event.target)) {
                setShowUserPanel(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRefUser]);

    useEffect(() => {
        if (!pageLoaded) {
            HookAPI("", `PageManage/{userid}/${id}`).then(res => {
                const data = res.data;
                if (data.status === 200) {
                    setPageManage(data.data);
                }
            })
            setPageLoaded(true);
        }
    }, [pageLoaded])
    useEffect(() => {
        if (pageManage)
            setCreateList(pageManage.users);
    }, [pageManage])

    const searchValue = (e) => {
        setShowUserPanel(true);
        setUserPanelLoaded(true);
        if (e.target.value !== null && e.target.value !== "") {
            HookAPI(null, "/web/{userid}/search/" + e.target.value, methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    setUsers(response.data);
                    setUserPanelLoaded(false);
                }
            });
        } else {
            setUserPanelLoaded(false);
            setShowUserPanel(false);
        }
    }
    const renderInfoInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id, e)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }

    const addCreateList = (userId) => {

        var list = [...createList];
        list.push(users.find(el => el.id === userId));
        setCreateList(list);
    }

    const saveData = () => {

        let prepareData = [];
        createList.forEach((item) => {
            prepareData.push(item.id);
        })

        HookAPI(prepareData,`PageManage/{userid}/${pageManage.id}/batchShare`,methodTypes.PUT)

    }


    const removeFromCreateList = (dataId) => {
        let list = createList;
        if (list.find(el => el.id === dataId)) {
            list.splice(list.findIndex(el => el.id === dataId), 1);
            // console.log(list);
            setCreateList([...list]);
        }
    }
    return (
        <div id="content" className="p-4 p-md-5">
            <Navbar />
            <div className="container-content">
                <div className="card-hover">
                    <h3 className="card-title component-header">
                        Active Messenger
                        </h3>
                </div>
                <div className="card card-init-css" >
                    {

                        pageLoaded ? (

                            <div className="card card-tab">
                                <h5 className="card-title">Setup</h5>

                                <div className="card-body">
                                    <span className="small-text">Choose user</span>

                                    <small id="nameHelp" className="form-text text-muted">can choose multiple user</small>
                                    <div className="Customize-lead">
                                        <div className="card-body">
                                            <div className="combobox" ref={wrapperRefUser} name="user">
                                                <div className="combobox container-input">
                                                    <span className="fa fa-cubes" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                    <input type="text" placeholder={"search user to share"} onChange={(e) => searchValue(e)} />
                                                    <span className="button-icon ion-md-arrow-dropdown"></span>
                                                </div>
                                                <div className={`listbox d-${showUserPanel ? "block" : "none"}`}>
                                                    <ul>
                                                        {(
                                                            users.map((item) => renderInfoInDroplist(item, addCreateList))
                                                        )}
                                                    </ul>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {
                                    createList.length > 0 && (



                                        <div className="w-100" >
                                            <h5>Has been shared for</h5>
                                            <small id="nameHelpList" className="form-text text-muted">Click and drag to change position. This order will be the data sharing order</small>
                                            {
                                                createList.map((item, index) => (
                                                        <UserShareMessageRow key={`user-item-${item.id}`} data={item} remove={removeFromCreateList} />
                                                ))
                                            }





                                        </div>

                                    )
                                }
                                <CardFooter>
                                    <Button color={'success'} onClick={() => saveData()}>Save</Button>
                                </CardFooter>
                            </div>

                        ) : (
                            <Spinner color={"info"} className={`m-auto`} />
                        )
                    }
                </div>


            </div>
        </div >
    )

}
export default PageManageShare