import React, { Component } from 'react';
import Navbar from "../Navbar";
import HookAPI from "../../utility/HookAPI";
import { Table, Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap'

class ListFliGShareData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }



    componentDidMount() {
        HookAPI(null, "datasharing/{userid}/data").then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    listData: response.data.data
                })
            }
        })
    }


    confirmData = (id) => {
        HookAPI(null, "datasharing/{userid}/confirm/" + id)
    }

    rejectData = (id) => {
        HookAPI(null, "datasharing/{userid}/reject/" + id)
    }


    render() {
        const { listData } = this.state;
        listData.reverse();
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            List of Data
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab" style={{ width: "fit-content" }}>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Data ID</th>
                                        <th>User share</th>
                                        <th>Campain name</th>
                                        <th>Group name</th>
                                        <th>Data of User</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listData.length > 0 ?
                                            listData.map((element, index1) => (
                                                <>
                                                    <tr key={index1}>
                                                        <th scope="row">{element.id}</th>
                                                        <th>{element.data.id}</th>
                                                        <td>{element.shareUser.name}</td>
                                                        <td>{element.data.rawData.campainName}</td>
                                                        <td>{element.data.rawData.adsetName}</td>
                                                        <td>{element.data.user.name}</td>
                                                        <td>
                                                            <Button outline color='info' id={`collapHandle-${element.id}`}>Detail</Button>&nbsp;
                                                            {

                                                                !element.isConfirm &&
                                                                (
                                                                    
                                                                    <>
                                                                    <Button outline color='success' onClick={this.confirmData.bind(this, element.id)}>Confirm Data</Button>&nbsp;
                                                                    <Button outline color='danger' onClick={this.rejectData.bind(this, element.id)}>Reject Data</Button>
                                                                    </>
                                                                )

                                                            }
                                                        </td>

                                                    </tr>
                                                    <tr key={`data-${element.id}`}>
                                                        <td colSpan={7}>
                                                            <UncontrolledCollapse toggler={`collapHandle-${element.id}`}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <Table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Ad name</th>
                                                                                    <td>{element.data.rawData.adName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Ad set name</th>
                                                                                    <td>{element.data.rawData.adsetName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Advertise name</th>
                                                                                    <td>{element.data.rawData.advertiseName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>campain name</th>
                                                                                    <td>{element.data.rawData.campainName}</td>
                                                                                </tr>

                                                                                {
                                                                                    Object.entries(element.data.rawData.field).map((el, id1) => (
                                                                                        <tr key={id1}>
                                                                                            <th>{el[0]}</th>
                                                                                            <td>{(el[1].toString()).replace(",", " ").replace("[", "").replace("]", "")}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                }



                                                                            </tbody>
                                                                        </Table>
                                                                    </CardBody>
                                                                </Card>
                                                            </UncontrolledCollapse>

                                                        </td>

                                                    </tr>
                                                </>
                                            )) :
                                            (
                                                <tr>
                                                    <td colSpan={7}>Not thing to show!</td>
                                                </tr>
                                            )
                                    }

                                </tbody>
                            </Table>
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default ListFliGShareData;