import axios from 'axios';
import * as methodTypes from '../Constants/method.constants';
import Cookies from "js-cookie";
import message from "@davistran86/notification"
const directLink = "https://meai.vn/meai/api/v1/";
export default function HookAPI(data = null, endpoint, method = null, query = {}) {

    endpoint = endpoint.replace("{userid}", Cookies.get("_uid"));
    if (Cookies.get("_tkl") != undefined) {
        endpoint = endpoint + "?token=" + Cookies.get("_tkl");
    }
    const arrQuery = Object.entries(query);
    arrQuery.forEach((item, index) => {
        if (item[1] !== "null")
            endpoint += "&" + item[0] +"="+item[1];
    })

    if (method === methodTypes.GET || method === null) {
        return axios({
            method: methodTypes.GET,
            url: directLink + endpoint
        }).then(
            response => {
                if (response.data.data.action != undefined) {
                    if (response.data.data.action === "logout") {
                        Cookies.set("_uid", -1, { expires: 0, path: '/' });
                        Cookies.set("_tkl", -1, { expires: 0, path: '/' });
                        window.location.reload();
                    } else if (response.data.data.action === "debugmode" && Cookies.get("_uid") != -1) {
                        Cookies.set("_uid", -1, { expires: 1, path: '/' });
                        window.location.reload();
                    } else if (response.data.data.action === "releasemode" && Cookies.get("_uid") == -1) {
                        Cookies.set("_uid", -1, { expires: 0, path: '/' });
                        Cookies.set("_tkl", -1, { expires: 0, path: '/' });
                        window.location.reload();
                    }
                };

                if (response.data.data.message != undefined && response.data.data.message != null) {
                    if (response.data.code === 200) {
                        message.success({ title: response.data.data.title, description: response.data.data.message }, {
                            width: Math.min(window.innerWidth * 0.8, 400) + "px"
                        });
                    } else {
                        message.error({ title: response.data.data.title, description: response.data.data.message }, {
                            width: Math.min(window.innerWidth * 0.8, 400) + "px"
                        });
                    }

                }
                return response;
            }
        ).catch(error => {
            message.error({ title: "Extenal Server Error", description: error.message }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })

            error.data = {
                data: {
                    message: error.message,
                    title: "Extenal Server Error",
                    status: 500
                }
            }
            return error;
        }

        );


    } else {
        return axios(directLink + endpoint, {
            method: method,
            data: data

        }).then(response => {
            if (response.data.data.action != undefined) {

                if (response.data.data.action === "logout") {
                    Cookies.set("_uid", -1, { expires: 0, path: '/' });
                    Cookies.set("_tkl", -1, { expires: 0, path: '/' });
                    window.location.reload();
                } else if (response.data.data.action === "debugmode" && Cookies.get("_uid") != -1) {
                    Cookies.set("_uid", -1, { expires: 1, path: '/' });
                    window.location.reload();
                } else if (response.data.data.action === "releasemode" && Cookies.get("_uid") == -1) {
                    Cookies.set("_uid", -1, { expires: 0, path: '/' });
                    Cookies.set("_tkl", -1, { expires: 0, path: '/' });
                    window.location.reload();
                }
            };
            if (response.data.data.message != undefined) {
                console.log(Math.min(window.innerWidth * 0.8, 400) + "px");
                if (response.data.code === 200) {
                    message.success({ title: response.data.data.title, description: response.data.data.message }, {
                        width: Math.min(window.innerWidth * 0.8, 400) + "px",
                    });
                } else {
                    message.error({ title: response.data.data.title, description: response.data.data.message }, {
                        width: Math.min(window.innerWidth * 0.8, 400) + "px",
                    });
                }

            }
            return response;
        }).catch(error => {
            message.error({ title: "Extenal Server Error", description: error.message }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px",
            })

            error.data = {
                data: {
                    message: error.message,
                    title: "Extenal Server Error",
                    status: 500
                }
            }
            return error;
        }
        );
    }

}