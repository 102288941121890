import React, { useEffect, useRef, useState,lazy } from 'react'
import HookAPI from '../../utility/HookAPI'
import useOutsideClick from '../../utility/useOutsideClick';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Navbar from '../Navbar'
import { Card, CardImg, CardBody, CardTitle, CardText, Spinner, List, ListInlineItem, Button } from "reactstrap"
import "./css/index.css"
import { DELETE, POST } from '../../Constants/method.constants';
import { IoReload } from "react-icons/io5"
import { Link, useHistory } from 'react-router-dom';
import { CHAT_ROOT } from '../../Constants/Config.constants';


const CreatePageManage = (props) => {
    const wrapperPersonAccount = useRef();
    const [pageLoaded, setPageLoaded] = useState(false)
    const [showAccountPanel, setShowAccountPanel] = useState(false);
    const [pageUtility, setPageUtility] = useState(null)
    const [facebookPages, setFacebookPages] = useState([])
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [loadedFacebookPage, setLoadedFacebookPage] = useState(false);
    const [isConnecting, setConnecting] = useState([]);
    const [isTesting, setTesting] = useState([]);
    const history = useHistory();


    useEffect(() => {
        if (!pageLoaded) {
            HookAPI("", "PageManage/personaccount/{userid}").then((res) => {
                const response = res.data;
                if (response.status === 200) {
                    setAccounts(response.data);
                }
            });
            HookAPI("", "PageManage/facebooklogin/{userid}").then((res) => {
                const response = res.data;
                if (response.status === 200) {
                    setPageUtility(response.data);
                }
            })
            setPageLoaded(true);
        }
    }, [pageLoaded])
    useEffect(() => {
        if (selectedAccount != null && selectedAccount != {}) {
            setLoadedFacebookPage(false)
            HookAPI("", `PageManage/{userid}/getPages/${selectedAccount.id}`).then(res => {
                const response = res.data;
                if (response.status === 200) {
                    setFacebookPages(response.data);
                    let a = [];
                    let b = [];
                    response.data.forEach(e => {
                        a.push(false);
                        b.push(false);
                    })
                }
                setLoadedFacebookPage(true)
            })

        }
    }, [selectedAccount])

    useOutsideClick(wrapperPersonAccount, () => {
        if (showAccountPanel)
            setShowAccountPanel(false);
    })

    const renderAccount = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }

    const testPageConnection = (pageId, index) => {

        const testing = isTesting;
        testing[index] = true;
        setTesting([...testing]);

        HookAPI("", `PageManage/{userid}/checking/${selectedAccount.id}/${pageId}`).then(res => {
            const response = res.data;
            if (response.status === 200) {
                // const itemIndex = list.findIndex(el => el.id === pageId);
                if (index > -1) {
                    const list = facebookPages;
                    list[index].ready_to_connect = true;
                    setFacebookPages([...list])
                    testing[index] = false;
                    setTesting([...testing]);
                }
            }
        })
    }
    const connectPage = (pageId, index) => {
        const testing = isConnecting;
        testing[index] = true;
        setConnecting([...testing]);
        let prepareData = {};
        prepareData.faID = selectedAccount.id;
        prepareData.pageid = pageId;
        HookAPI(prepareData, 'PageManage/{userid}', POST).then(res => {
            if (res.status === 200) {
                const list = facebookPages;
                list[index].isActive = true;
                list[index].page_id = res.data.data.id;
                setFacebookPages([...list])
            }
            testing[index] = false;
            setConnecting([...testing]);
        })
    }

    const removeConnectedPage = (pageId, index) => {
        confirmAlert({
            title: 'Do you want unlink this page',
            message: 'If you accept this action. All data about Conversation, setting. .v.v. Will be remove!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        HookAPI("", `PageManage/{userid}/${pageId}`, DELETE).then(res => {
                            if (res.status === 200) {
                                const list = facebookPages;
                                list[index].isActive = false;
                                setFacebookPages([...list])
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return; }
                }
            ]
        });

    }

    const createAnAccount = () => {
        var handleWindow = window.open(pageUtility.URL_LOGIN_FACEBOOK, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + (window.innerHeight - 640) / 2 + ",left=" + (window.innerWidth - 640) / 2 + ",width=640,height=640");

        var timer = setInterval(function () {
            if (handleWindow.closed) {
                clearInterval(timer);
                HookAPI("", "PageManage/personaccount/{userid}").then((res) => {
                    const response = res.data;
                    if (response.status === 200) {
                        setAccounts(response.data);
                    } else {
                        setAccounts([])
                    }
                })

                console.clear();
            }
        }, 1000, this);
    }

    const reloadSession = () => {
        setPageLoaded(false);
        setShowAccountPanel(false);
        setPageUtility(null);
        setFacebookPages([]);
        setAccounts([]);
        setSelectedAccount(null);
        setSelectedPage(null);
        setLoadedFacebookPage(false);
        setConnecting([]);
        setTesting([]);
    }

    const goPage = (id) => {
        window.location.href = `https://meai.vn/chat/${id}`;
    }



    return (

        <div id="content" className="p-4 p-md-5">
            <Navbar />
            <div className="container-content">
                <div className="card-hover">
                    <h3 className="card-title component-header">
                        Active Messenger
                        </h3>
                </div>
                <div className="card card-init-css" >
                    {

                        pageLoaded ? (

                            <div className="card card-tab" style={{ width: "max(768px,75%)" }}>

                                <h5 className="card-title">Setup</h5>

                                <div className="card-body">
                                    <span className="small-text">Choose Linked Account</span>

                                    <small id="nameHelp" className="form-text text-muted">Only the permission accounts are displayed</small>
                                    <div className="Customize-lead">
                                        <div className="card-body">
                                            <div className="combobox" ref={wrapperPersonAccount} name="facebook_account">
                                                <div className="combobox container-input" onClick={() => setShowAccountPanel(true)}>
                                                    <span className="fa fa-cubes" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                    <input type="text" placeholder={"Click to choose account"} readOnly={true} defaultValue={(selectedAccount != null && selectedAccount != {}) ? selectedAccount.name : ""} />
                                                    <span className="button-icon ion-md-arrow-dropdown"></span>
                                                </div>
                                                <div className={`listbox d-${showAccountPanel ? "block" : "none"}`}>
                                                    <ul>
                                                        {(
                                                            accounts.map((item) => renderAccount(item, setSelectedAccount))
                                                        )}
                                                        <li>
                                                            <div className="listbox-create-facebook" onClick={() => createAnAccount()}>
                                                                <span>+ Add new Account</span>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {
                                    selectedAccount != null && (
                                        <div className="card-body">
                                            <div className="Customize-lead text-center">

                                                {
                                                    loadedFacebookPage ? (
                                                        <div className="card-body">
                                                            <h6 >Linked Page</h6>
                                                            <div className={`row page-container active-container`}>
                                                                {
                                                                    facebookPages.filter(page => page.isActive).map((page, index) => (
                                                                        <div key={`page-item activated-page-${page.id}-${index}`} className={`col-3`}>
                                                                            <Card className={`page-item`}>
                                                                                <CardImg top src={`${page.logo}`} alt={`logo of page: ${page.name}`} className={"page-image mx-auto mt-3"} />
                                                                                <CardBody>
                                                                                    <CardTitle tag="h6" className={"page-name"}>{page.name}</CardTitle>
                                                                                </CardBody>
                                                                                <List type="inline">

                                                                                    <ListInlineItem><Button color={"info"} outline size={"sm"} onClick={() => goPage(page.page_id)}>Go to Chat</Button>
                                                                                    </ListInlineItem>
                                                                                    <ListInlineItem>
                                                                                    <Link to={`/Pagemanager/${page.page_id}`}><Button color={"primary"} outline size={"sm"} >Edit</Button></Link>
                                                                                    </ListInlineItem>
                                                                                    <ListInlineItem>
                                                                                        <Button color={"danger"} outline size={"sm"} onClick={() => removeConnectedPage(page.id, index)}>Remove</Button>
                                                                                    </ListInlineItem>
                                                                                </List>
                                                                            </Card>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                            <h6 >Un-link page</h6>
                                                            <div className={`row page-container un-active-container`}>
                                                                {
                                                                    facebookPages.filter(page => !page.isActive).map((page, index) => (
                                                                        <div key={`page-item un-active-page-${page.id}-${index}`} className={`col-3`}>

                                                                            <Card className={`page-item`}>
                                                                                <CardImg top src={`${page.logo}`} alt={`logo of page: ${page.name}`} className={"page-image mx-auto mt-3"} />
                                                                                <CardBody>
                                                                                    <CardTitle tag="h6" className={"page-name"}>{page.name}</CardTitle>
                                                                                    <List type="inline">
                                                                                        {
                                                                                            page.ready_to_connect ? (
                                                                                                <ListInlineItem>
                                                                                                    <Button color={"success"} outline size={"sm"} onClick={() => connectPage(page.id, index)}>{isConnecting[index] ? <Spinner color={`info`} /> : "Connect Page"}</Button>
                                                                                                </ListInlineItem>
                                                                                            ) : (

                                                                                                <ListInlineItem>
                                                                                                    <Button color={"info"} outline size={"sm"} onClick={() => testPageConnection(page.id, index)}> {isTesting[index] ? <Spinner color={`info`} /> : "Test Connection"}</Button>
                                                                                                </ListInlineItem>
                                                                                            )
                                                                                        }
                                                                                    </List>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <Spinner color={`success`} />
                                                    )
                                                }


                                            </div>
                                        </div>
                                    )
                                }

                                {

                                    <span style={{ fontSize: "14px" }} className={`reload-session`} onClick={() => reloadSession()}><IoReload /> Reload</span>
                                }
                            </div>

                        ) : (
                            <Spinner color={"info"} className={`m-auto`} />
                        )
                    }
                </div>


            </div>
        </div >

    )
}

export default CreatePageManage