import React, { Component } from 'react';
import Navbar from "../Navbar";
import { Form } from "react-bootstrap";
import * as methodTypes from "../../Constants/method.constants";
import HookAPI from "../../utility/HookAPI";
import { Redirect } from 'react-router-dom';
import message from "@davistran86/notification"

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            pageManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            formManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            gaccountManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            googleSheetManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false,
            },
            sheetManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false,
            },
            formFieldManage: [
                {
                    id: 1,
                    name: "abc"
                },
                {
                    id: 2,
                    name: "def"
                },
            ],
            sheetFieldManage: [[]],
            confirmDataFacebook: false,
            confirmDataGoogle: false,
            confirmTestFacebook: false,
            testFormSuccess: false,
            sampleField: [
            ],
            showPanelChooseForm: [],
            showPanelChooseFormEmail: [],
            URL_LOGIN_FACEBOOK: "",
            URL_LOGIN_GOOGLE: "",
            app_name: false,
            isSendEmail: false,
            emailFieldManage: [[]],
            sampleEmailField: [
                {
                    id: 0,
                    name: "Email send to"
                },
                {
                    id: 1,
                    name: "Email Subject"
                },
                {
                    id: 2,
                    name: "Email Content"
                },
            ],
            testData: false,
            isShowLoading: false,
            loadingEditData: true,
            id: -1,
            isCreated: false
        }
        this.wrapperRef = [];
        this.wrapperRefDataExel = [];
        this.wrapperRefDataEmail = [];
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setupToggleOff = () => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (typeof object[1] === "object") {
                object[1].toggleSelect = false;
                this.setState({
                    object: {
                        ...object[1],
                    }
                });
            }
        })
    }

    setupDataEmailToggleOff = () => {
        let showPanel = this.state.showPanelChooseFormEmail;
        for (var i = 0; i < showPanel.length; i++) {
            showPanel[i] = false;
        }
        this.setState({
            showPanelChooseFormEmail: showPanel
        })
    }

    setupDataExcelToggleOff = () => {
        let showPanel = this.state.showPanelChooseForm;
        for (var i = 0; i < showPanel.length; i++) {
            showPanel[i] = false;
        }
        this.setState({
            showPanelChooseForm: showPanel
        })
    }

    setupToggle = (data, toggle) => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (typeof object[1] === "object") {
                if (object[0] === data) {
                    object[1].toggleSelect = toggle;

                    this.setState({
                        object: {
                            ...object[1]
                        }
                    })
                } else {
                    object[1].toggleSelect = false;
                    this.setState({
                        object: {
                            ...object[1],
                        }
                    });
                }

            }
        })
    }

    toggleCollapseAccount = () => {
        let { accountManage } = this.state;
        this.setupToggle("accountManage", !accountManage.toggleSelect);
        if (accountManage.toggleSelect) {
            accountManage.isLoading = true;
            this.setState({
                accountManage: accountManage
            })
            HookAPI("", "FacebookService/{userid}", methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        accountManage: {
                            selected: accountManage.selected,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: this.state.accountManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        accountManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.accountManage.toggleSelect
                        }
                    })
                }
            }
            )
        }


    };

    toggleCollapsePage = () => {
        let { pageManage } = this.state;
        this.setupToggle("pageManage", !pageManage.toggleSelect);
        if (pageManage.toggleSelect) {
            pageManage.isLoading = true;
            this.setState({
                pageManage: pageManage
            })
            HookAPI("", "FacebookService/{userid}/" + this.state.accountManage.selected, methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        pageManage: {
                            selected: pageManage.selected,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: this.state.pageManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        pageManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.pageManage.toggleSelect
                        }
                    })
                }
            }
            )
        }
    };

    toggleCollapseForm = () => {
        let { formManage } = this.state;
        this.setupToggle("formManage", !formManage.toggleSelect);
        if (formManage.toggleSelect) {
            formManage.isLoading = true;
            this.setState({
                formManage: formManage
            })
            HookAPI("", "FacebookService/{userid}/" + this.state.accountManage.selected + "/" + this.state.pageManage.selected, methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        formManage: {
                            selected: formManage.selected,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: this.state.formManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        formManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.formManage.toggleSelect
                        }
                    })
                }
            }
            )
        }
    };

    toggleCollapseGAccount = () => {
        const { gaccountManage } = this.state;
        this.setupToggle("gaccountManage", !gaccountManage.toggleSelect);

        if (gaccountManage.toggleSelect) {
            HookAPI("", "google/{userid}", methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        gaccountManage: {
                            selected: gaccountManage.selected,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: this.state.gaccountManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        gaccountManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.gaccountManage.toggleSelect
                        }
                    })
                }
            }
            )
        }
    }

    toggleCollapseDrive = () => {
        let { googleSheetManage } = this.state;
        this.setupToggle("googleSheetManage", !googleSheetManage.toggleSelect);

        if (googleSheetManage.toggleSelect) {
            googleSheetManage.isLoading = true;
            this.setState({
                googleSheetManage: googleSheetManage
            })
            HookAPI("", "google/{userid}/" + this.state.gaccountManage.selected).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        googleSheetManage: {
                            selected: googleSheetManage.selected,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.googleSheetManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        googleSheetManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.googleSheetManage.toggleSelect
                        }
                    })
                }

            })
        }
    }

    toggleCollapseSheet = () => {
        let { sheetManage } = this.state;
        this.setupToggle("sheetManage", !sheetManage.toggleSelect);

        if (sheetManage.toggleSelect) {
            sheetManage.isLoading = true;
            this.setState({
                sheetManage: sheetManage
            })
            HookAPI("", "google/{userid}/" + this.state.gaccountManage.selected + "/" + this.state.googleSheetManage.selected).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        sheetManage: {
                            selected: sheetManage.selected,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.sheetManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        sheetManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.sheetManage.toggleSelect
                        }
                    })
                }

            })
        }
    }
    buttonCofirm = () => {
        this.setState({
            confirmDataFacebook: true
        })
    }

    buttonConfirmGoogle = () => {


        HookAPI("", "google/{userid}/" + this.state.gaccountManage.selected + "/" + this.state.googleSheetManage.selected + "/" + (this.state.sheetManage.list.find(element => element.id === this.state.sheetManage.selected)).name).then((response) => {
            if (response.data.status === 200) {
                let data = response.data.data;
                this.setState({
                    sampleField: data
                })
            }

        })
        this.setState({
            confirmDataGoogle: true
        })
    }

    buttonTestFormFacebook = () => {
        HookAPI("", "FacebookService/{userid}/" + this.state.accountManage.selected + "/" + this.state.pageManage.selected + "/" + this.state.formManage.selected).then((response) => {
            if (response.data.status === 200) {
                let data = response.data.data;
                this.setState({
                    formFieldManage: data
                })
            } else {
                this.setState({
                    formFieldManage: []
                })
            }
            this.setState({
                testFormSuccess: true,
            })
        })
    }

    buttonReverseFacebook = () => {
        this.setState({
            confirmDataFacebook: false,
            testFormSuccess: false
        })
    }


    buttonReverseGoogle = () => {
        this.setState({
            confirmDataGoogle: false,
            testData: false
        })
    }

    selectedFacebookAccount = (id) => {
        const { accountManage } = this.state;
        this.setState({
            accountManage: {
                selected: id,
                list: accountManage.list,
                isLoading: accountManage.isLoading,
                toggleSelect: false
            }


        });
        if (accountManage.selected !== id) {
            HookAPI("", "FacebookService/{userid}/" + id).then((response) => {

                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        pageManage: {
                            selected: null,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.pageManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        pageManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.pageManage.toggleSelect
                        }
                    })
                }

            })
        }
    }

    selectedFacebookPage = (id) => {
        const { pageManage, accountManage } = this.state;
        this.setState({
            pageManage: {
                selected: id,
                list: pageManage.list,
                isLoading: pageManage.isLoading,
                toggleSelect: false
            }
        });
        if (pageManage.selected !== id) {
            HookAPI("", "FacebookService/{userid}/" + accountManage.selected + "/" + id).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        formManage: {
                            selected: null,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.formManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        formManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.formManage.toggleSelect
                        }
                    })
                }

            })
        }
    }

    selectedGoogleAccount = (id) => {
        const { gaccountManage } = this.state;
        this.setState({
            gaccountManage: {
                selected: id,
                list: gaccountManage.list,
                isLoading: gaccountManage.isLoading,
                toggleSelect: false
            }
        })
        if (gaccountManage.selected !== id) {
            HookAPI("", "google/{userid}/" + id).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        googleSheetManage: {
                            selected: null,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.googleSheetManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        googleSheetManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.googleSheetManage.toggleSelect
                        }
                    })
                }

            })
        }

    }

    selectedFacebookForm = (id) => {
        const { formManage, accountManage, pageManage } = this.state;
        this.setState({
            formManage: {
                selected: id,
                list: formManage.list,
                isLoading: formManage.isLoading,
                toggleSelect: false
            }
        });
        if (formManage.selected !== id) {
            HookAPI("", "FacebookService/{userid}/" + accountManage.selected + "/" + pageManage.selected + "/" + id).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        formFieldManage: data
                    })
                } else {
                    this.setState({
                        formFieldManage: []
                    })
                }

            })
        }

    }

    selectedGoogleSpreadSheet = (id) => {
        const { googleSheetManage, gaccountManage } = this.state;
        this.setState({
            googleSheetManage: {
                selected: id,
                list: googleSheetManage.list,
                isLoading: googleSheetManage.isLoading,
                toggleSelect: false
            }
        })
        if (googleSheetManage.selected !== id) {
            HookAPI("", "google/{userid}/" + gaccountManage.selected + "/" + id).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        sheetManage: {
                            selected: null,
                            list: data,
                            isLoading: false,
                            toggleSelect: this.state.sheetManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        sheetManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.sheetManage.toggleSelect
                        }
                    })
                }

            })
        }
    }

    selectedSheet = (id) => {
        const { gaccountManage, googleSheetManage, sheetManage } = this.state;
        this.setState({
            sheetManage: {
                selected: id,
                list: sheetManage.list,
                isLoading: sheetManage.isLoading,
                toggleSelect: false
            }
        })

        if (sheetManage.selected !== id) {
            HookAPI("", "google/{userid}/" + gaccountManage.selected + "/" + googleSheetManage.selected + "/" + (sheetManage.list.find(element => element.id === id)).name).then((response) => {
                if (response.data.status === 200) {
                    let data = response.data.data;
                    this.setState({
                        sampleField: data
                    })
                }

            })
        }
    }


    renderInfoInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id, e)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }


    handleSwitch = (e) => {
        this.setState({
            isSendEmail: !this.state.isSendEmail
        })
    }


    renderInputDroplist = (data) => {
        let { sheetFieldManage, formFieldManage, showPanelChooseForm } = this.state;

        const removeTags = (id, element) => {
            sheetFieldManage[id] = sheetFieldManage[id].filter((el) => el.id !== element.id);
            this.setState({
                sheetFieldManage: sheetFieldManage
            })
        };

        const triggerShowPanelForm = (id) => {
            var currTrigger = showPanelChooseForm[id];
            for (var i = 0; i < showPanelChooseForm.length; i++) {
                showPanelChooseForm[i] = false;
            }
            showPanelChooseForm[id] = !currTrigger;
            this.setState({
                showPanelChooseForm: showPanelChooseForm
            })
        }


        const addTags = (id, e) => {

            var object = {
                id: e.target.value,
                name: e.target.value
            }

            if (e.target.value !== "") {
                if (typeof sheetFieldManage[id] === "object") {
                } else {
                    sheetFieldManage[id] = [];
                }
                sheetFieldManage[id].push(object);
                this.setState({
                    sheetFieldManage: sheetFieldManage
                })
                e.target.value = "";
            }
        }

        const addTagsSelected = (data, id) => {
            if (typeof sheetFieldManage[id] === "object") {
            } else {
                sheetFieldManage[id] = [];
            }
            sheetFieldManage[id].push(data);
            this.setState({
                sheetFieldManage: sheetFieldManage
            })
        }

        return (
            <div key={data.id} style={{ marginTop: "15px" }}>
                <hr className="my-1" />
                <span className="small-text">{data.name}</span>
                <div className="Customize-lead" >
                    <div className="card-body">
                        <div className="combobox" ref={(node) => { this.wrapperRefDataExel[data.id] = node }}>
                            <div className="combobox container-input tags-input">
                                <ul className="tags">
                                    {
                                        (sheetFieldManage[data.id]) && (sheetFieldManage[data.id].map((element, index) => (
                                            <li key={index} className="tag">
                                                <span className="tag-title">{element.name == null ? element.id : element.name}</span>
                                                <span className="ion-md-close-circle tag-close-icon" onClick={() => removeTags(data.id, element)}></span>
                                            </li>
                                        )))
                                    }
                                </ul>
                                <input
                                    type="text"
                                    onKeyUp={event => event.key === "Enter" ? addTags(data.id, event) : null}
                                    onClick={triggerShowPanelForm.bind(this, data.id)}
                                    placeholder={`Enter or choose ${data.name}`}

                                />
                                {
                                    (showPanelChooseForm[data.id]) && (
                                        <div className={`listboxPanel d-block`}>
                                            <ul className="tags">
                                                {formFieldManage.length > 0 ? (
                                                    formFieldManage.map((fieldForm) => (
                                                        <li key={fieldForm.id} onClick={() => addTagsSelected(fieldForm, data.id)} className="tag">
                                                            <div>
                                                                <p><span className="tag-title">{fieldForm.id} : {fieldForm.name ? fieldForm.name : <i>null</i>}</span></p>

                                                            </div>

                                                        </li>
                                                    ))
                                                ) : (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>)}
                                            </ul>

                                        </div>
                                    )
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderInputDroplistEmail = (data) => {
        let { emailFieldManage, formFieldManage, showPanelChooseFormEmail } = this.state;
        const removeTags = (id, element) => {
            emailFieldManage[id] = emailFieldManage[id].filter((el) => el.id !== element.id);
            this.setState({
                emailFieldManage: emailFieldManage
            })
        };

        const triggerShowPanelForm = (id) => {
            var currTrigger = showPanelChooseFormEmail[id];
            for (var i = 0; i < showPanelChooseFormEmail.length; i++) {
                showPanelChooseFormEmail[i] = false;
            }
            showPanelChooseFormEmail[id] = !currTrigger;
            this.setState({
                showPanelChooseFormEmail: showPanelChooseFormEmail
            })
        }



        const addTags = (id, e) => {

            var object = {
                id: e.target.value,
                name: e.target.value
            }

            if (e.target.value !== "") {
                if (typeof emailFieldManage[id] === "object") {
                } else {
                    emailFieldManage[id] = [];
                }
                emailFieldManage[id].push(object);
                this.setState({
                    emailFieldManage: emailFieldManage
                })
                e.target.value = "";
            }
        }

        const addTagsSelected = (data, id) => {
            if (typeof emailFieldManage[id] === "object") {
            } else {
                emailFieldManage[id] = [];
            }
            emailFieldManage[id].push(data);
            this.setState({
                emailFieldManage: emailFieldManage
            })
        }

        return (
            <div key={data.id} style={{ marginTop: "15px" }}>
                <hr className="my-1" />
                <span className="small-text">{data.name}</span>
                <div className="Customize-lead" >
                    <div className="card-body">
                        <div className="combobox" ref={(node) => { this.wrapperRefDataEmail[data.id] = node }}>
                            <div className="combobox container-input tags-input">
                                <ul className="tags">
                                    {
                                        (emailFieldManage[data.id]) && (emailFieldManage[data.id].map((element, index) => (
                                            <li key={index} className="tag">
                                                <span className="tag-title">{element.name == null ? element.id : element.name}</span>
                                                <span className="ion-md-close-circle tag-close-icon" onClick={() => removeTags(data.id, element)}></span>
                                            </li>
                                        )))
                                    }
                                </ul>
                                <input
                                    type="text"
                                    onKeyUp={event => event.key === "Enter" ? addTags(data.id, event) : null}
                                    onClick={triggerShowPanelForm.bind(this, data.id)}
                                    placeholder={`Enter or choose ${data.name}`}

                                />
                                {
                                    (showPanelChooseFormEmail[data.id]) && (
                                        <div className={`listboxPanel d-block`}>
                                            <ul className="tags">
                                                {formFieldManage.length > 0 ? (
                                                    formFieldManage.map((fieldForm) => (
                                                        <li key={fieldForm.id} onClick={() => addTagsSelected(fieldForm, data.id)} className="tag">
                                                            <div>
                                                                <p><span className="tag-title">{fieldForm.id} : {fieldForm.name ? fieldForm.name : <i>null</i>}</span></p>

                                                            </div>

                                                        </li>
                                                    ))
                                                ) : (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>)}
                                            </ul>

                                        </div>
                                    )
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    openFacebookLoginWindow = () => {
        let handleWindow = window.open(this.state.URL_LOGIN_FACEBOOK, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + (window.innerHeight - 640) / 2 + ",left=" + (window.innerWidth - 640) / 2 + ",width=640,height=640");
        var timer = setInterval(function (state) {
            if (handleWindow.closed) {
                clearInterval(timer);
                HookAPI("", "FacebookService/{userid}", methodTypes.GET).then((response) => {
                    response = response.data;
                    if (response.status === 200) {
                        state.setState({
                            accountManage: {
                                selected: null,
                                list: response.data,
                                isLoading: false,
                                toggleSelect: true
                            }
                        })
                    } else {
                        state.setState({
                            accountManage: {
                                selected: null,
                                list: [],
                                isLoading: false,
                                toggleSelect: true
                            }
                        })
                    }
                })
                
                console.clear();
            }
        }, 1000, this);
    }

    openGoogleLoginWindow = () => {
        let handleWindow = window.open(this.state.URL_LOGIN_GOOGLE, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + (window.innerHeight - 640) / 2 + ",left=" + (window.innerWidth - 640) / 2 + ",width=640,height=640");

        var timer = setInterval(function (state) {
            if (handleWindow.closed) {
                clearInterval(timer);
                HookAPI("", "google/{userid}", methodTypes.GET).then((response) => {
                    response = response.data;
                    if (response.status === 200) {
                        state.setState({
                            gaccountManage: {
                                selected: null,
                                list: response.data,
                                isLoading: false,
                                toggleSelect: state.state.gaccountManage.toggleSelect
                            }
                        })
                    } else {
                        state.setState({
                            gaccountManage: {
                                selected: null,
                                list: [],
                                isLoading: false,
                                toggleSelect: state.state.gaccountManage.toggleSelect
                            }
                        })
                    }
                }
                )
                console.clear();
            }
        }, 1000, this);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.find(el => (el != null && el.contains(event.target)))) {
            this.setupToggleOff();
        }
        if (this.wrapperRefDataEmail && !this.wrapperRefDataEmail.find(el => (el != null && el.contains(event.target)))) {
            this.setupDataEmailToggleOff();
        }
        if (this.wrapperRefDataExel && !this.wrapperRefDataExel.find(el => (el != null && el.contains(event.target)))) {
            this.setupDataExcelToggleOff();
        }

    }

    componentWillMount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        HookAPI(null, "web/{userid}" + this.props.location.pathname).then((response) => {
            if (response.data.status === 200) {
                const returnData = response.data.data;
                this.setState({
                    loadingEditData: false,
                    URL_LOGIN_FACEBOOK: returnData.URL_LOGIN_FACEBOOK,
                    URL_LOGIN_GOOGLE: returnData.URL_LOGIN_GOOGLE,
                    accountManage: {
                        selected: returnData.CONFIG.faccount.id,
                        list: [returnData.CONFIG.faccount],
                        isLoading: false,
                        toggleSelect: false
                    },
                    pageManage: {
                        selected: returnData.CONFIG.page.id,
                        list: [returnData.CONFIG.page],
                        isLoading: false,
                        toggleSelect: false
                    },
                    formManage: {
                        selected: returnData.CONFIG.form.id,
                        list: [returnData.CONFIG.form],
                        isLoading: false,
                        toggleSelect: false
                    },
                    gaccountManage: {
                        selected: returnData.CONFIG.gaccount.id,
                        list: [returnData.CONFIG.gaccount],
                        isLoading: false,
                        toggleSelect: false
                    },
                    googleSheetManage: {
                        selected: returnData.CONFIG.spreadsheet.id,
                        list: [returnData.CONFIG.spreadsheet],
                        isLoading: false,
                        toggleSelect: false,
                    },
                    sheetManage: {
                        selected: returnData.CONFIG.sheetname.id,
                        list: [returnData.CONFIG.sheetname],
                        isLoading: false,
                        toggleSelect: false,
                    },
                    app_name: returnData.CONFIG.lslName,
                    isSendEmail: false,
                    emailFieldManage: [[]],
                    sampleEmailField: [
                        {
                            id: 0,
                            name: "Email send to"
                        },
                        {
                            id: 1,
                            name: "Email Subject"
                        },
                        {
                            id: 2,
                            name: "Email Content"
                        },
                    ],
                    id: returnData.CONFIG.id
                })
            }
        });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    addAppName = (event) => {
        this.setState({
            app_name: event.target.value
        })
    }

    removeAppName = () => {
        this.setState({
            app_name: false
        })
    }


    submitData = () => {
        this.setState({
            isShowLoading: true
        })
        let dataPrepare = {};
        dataPrepare.app_name = this.state.app_name;
        dataPrepare.faccount_id = this.state.accountManage.selected;
        dataPrepare.page_id = this.state.pageManage.selected;
        dataPrepare.form_id = this.state.formManage.selected;
        dataPrepare.gaccount_id = this.state.gaccountManage.selected;
        dataPrepare.spreadsheet_id = this.state.googleSheetManage.selected;
        dataPrepare.wordsheet_id = (this.state.sheetManage.list.find(el => el.id === this.state.sheetManage.selected)).name;
        if (this.state.isSendEmail) {
            dataPrepare.is_send_email = true;
        }
        const { sheetFieldManage, emailFieldManage } = this.state;
        dataPrepare.field = [];
        dataPrepare.emailField = [];
        sheetFieldManage.forEach((element, index) => {
            dataPrepare.field[index] = [];
            var Stringid = "";
            element.forEach(el => {
                Stringid += el.id + " ";
            })
            dataPrepare.field[index] = Stringid;
        })
        emailFieldManage.forEach((element, index) => {
            dataPrepare.emailField[index] = [];
            var Stringid = "";
            element.forEach(el => {
                Stringid += el.id + " ";
            })
            dataPrepare.emailField[index] = Stringid;
        })

        if(dataPrepare.emailField[1] === ""){
            message.error({ title: "Email config Error", description: "You have enter mail subject. press enter to confirm email subject! " },{
                width:Math.min(window.innerWidth*0.8,400) + "px"
            });
            return;
        }
        if(dataPrepare.emailField[2] === ""){
            message.error({ title: "Email config Error", description: "You have enter mail content. press enter to confirm email content! " },{
                width:Math.min(window.innerWidth*0.8,400) + "px"
            });
            return;
        }
        this.setState({
            isCreated: true
            
        })
        HookAPI(dataPrepare, "web/{userid}" + this.props.location.pathname, methodTypes.POST).then((response) => {
            
            if (response.status !== 200) {
                this.setState({
                    isCreated: false
                })
            }else{
                this.setState({
                    isShowLoading:false
                })
            }
        });;

    }

    testData = () => {
        let dataPrepare = {};
        dataPrepare.app_name = this.state.app_name;
        dataPrepare.faccount_id = this.state.accountManage.selected;
        dataPrepare.page_id = this.state.pageManage.selected;
        dataPrepare.form_id = this.state.formManage.selected;
        dataPrepare.gaccount_id = this.state.gaccountManage.selected;
        dataPrepare.spreadsheet_id = this.state.googleSheetManage.selected;
        dataPrepare.wordsheet_id = (this.state.sheetManage.list.find(el => el.id === this.state.sheetManage.selected)).name;
        dataPrepare.leadgen_id = this.state.formFieldManage.find(el => el.id === "_leadgen_id_").name;
        if (this.state.isSendEmail) {
            dataPrepare.is_send_email = true;
        }
        const { sheetFieldManage, emailFieldManage } = this.state;
        dataPrepare.field = [];
        dataPrepare.emailField = [];
        sheetFieldManage.forEach((element, index) => {
            dataPrepare.field[index] = [];
            var Stringid = "";
            element.forEach(el => {
                Stringid += el.id + " ";
            })
            dataPrepare.field[index] = Stringid;
        })
        emailFieldManage.forEach((element, index) => {
            dataPrepare.emailField[index] = [];
            var Stringid = "";
            element.forEach(el => {
                Stringid += el.id + " ";
            })
            dataPrepare.emailField[index] = Stringid;
        })

        if(dataPrepare.emailField[1] === ""){
            message.error({ title: "Email config Error", description: "You have enter mail subject. press enter to confirm email subject! " },{
                width:Math.min(window.innerWidth*0.8,400) + "px"
            });
            return;
        }
        if(dataPrepare.emailField[2] === ""){
            message.error({ title: "Email config Error", description: "You have enter mail content. press enter to confirm email content! " },{
                width:Math.min(window.innerWidth*0.8,400) + "px"
            });
            return;
        }
        HookAPI(dataPrepare, "web/{userid}/test", methodTypes.POST).then((response) => {
            if (response.status === 200) {
                this.setState({
                    testData: true
                })
            } else {
                this.setState({
                    testData: false
                })
            }
        })
    }



    handleSearchGoogleSpreadName = (pathSearch, e) => {
        const searchSpreadName = e.target.value;

        let callUrl = "google/{userid}/" + this.state.gaccountManage.selected + "/" + pathSearch + "/" + searchSpreadName;
        if (searchSpreadName.length === 0) {
            callUrl = "google/{userid}/" + this.state.gaccountManage.selected;
        }

        HookAPI("", callUrl).then((response) => {
            if (response.data.status === 200) {
                let data = response.data.data;
                this.setState({
                    googleSheetManage: {
                        selected: null,
                        list: data,
                        isLoading: false,
                        toggleSelect: this.state.googleSheetManage.toggleSelect
                    }
                })
            } else {
                this.setState({
                    googleSheetManage: {
                        selected: null,
                        list: [],
                        isLoading: false,
                        toggleSelect: this.state.googleSheetManage.toggleSelect
                    }
                })
            }

        })


    }


    render() {
        const { accountManage, pageManage, formManage, confirmDataFacebook, testFormSuccess, gaccountManage, googleSheetManage, confirmDataGoogle, sheetManage, sampleField, formFieldManage, loadingEditData } = this.state;
        var pathname = this.props.location.pathname;
        if (pathname.replace("/Edit", "") === "") {
            return <Redirect to="/List" />
        }
        return (
            <div id="content" className="p-4 p-md-5">

                <Navbar />

                {!loadingEditData ? (<div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            Edit FliG
                            {
                                (this.state.app_name) && (
                                    <div style={{ display: "flex" }}>
                                        <p style={{ display: "flex" }}>Name: {this.state.app_name}</p>
                                        <span onClick={this.removeAppName.bind(this)} style={{ fontSize: 14, cursor: "pointer", marginLeft: 5 }}><i className="ion-md-create"></i></span>
                                    </div>
                                )
                            }
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        {
                            (this.state.isShowLoading) && (
                                <div className="loadingData">
                                    <div className="justify-content-between" style={{ textAlign: "center", position: "absolute", top: "50%", left: "50%" }}>
                                        <div className="spinner-grow spinner-grow-sm " role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>)
                        }


                        {(!this.state.app_name) && (<div className="card-body" >

                            <div className="card card-tab">
                                <h5 className="card-title">Named your app!</h5>
                                <div className="combobox">
                                    <div className="combobox container-input">
                                        <span className="button-icon ion-md-clipboard" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1, paddingTop: 0 }}></span>
                                        <input type="text"
                                            onKeyUp={event => event.key === "Enter" ? this.addAppName(event) : null}
                                            className="test" aria-describedby="nameHelp" />
                                        <span className="button-icon ion-md-checkbox-outline" style={{ position: "relative", opacity: 1, paddingTop: 0 }}></span>
                                    </div>
                                    <small id="nameHelp" className="form-text text-muted">Press Enter to submit.</small>
                                </div>

                            </div>


                        </div>)}

                        {(this.state.app_name !== "") && (
                            <div>
                                <div className="card-body" >


                                    <div className="card card-tab">
                                        <h5 className="card-title">Setup Facebook action</h5>
                                        <hr className="my-1" />
                                        {
                                            !confirmDataFacebook ? (
                                                <div className="card-body" >
                                                    <span>Choose Facebook lead account</span>
                                                    <div className="combobox" ref={(node) => this.wrapperRef[0] = node} name="accountManage">
                                                        <div className="combobox container-input" onClick={this.toggleCollapseAccount}>
                                                            <span className="fa fa-facebook-square" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                            <input type="text" value={accountManage.selected != null ? (accountManage.list.find(element => element.id === accountManage.selected)).name : ""} readOnly
                                                                className="test" />
                                                            <span className="button-icon ion-md-arrow-dropdown"></span>
                                                        </div>
                                                        <div className={`listbox d-${accountManage.toggleSelect ? "block" : "none"}`}>
                                                            <ul>
                                                                {accountManage.list.length > 0 ? (
                                                                    accountManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedFacebookAccount))
                                                                ) :
                                                                    (
                                                                        <div>
                                                                            <div className="justify-content-between" style={{ textAlign: "center" }}>
                                                                                {(accountManage.list.length === 0 && !accountManage.isLoading) ? (

                                                                                    <div className="" role="status">
                                                                                        <span>No data</span>
                                                                                    </div>
                                                                                ) : (
                                                                                        (accountManage.isLoading) && (<div className="spinner-grow spinner-grow-sm " role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                                        </div>)
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                <li>
                                                                    <div className="listbox-create-facebook" onClick={this.openFacebookLoginWindow}>
                                                                        <span>+ Add new Account</span>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                    {
                                                        accountManage.selected != null ? (
                                                            <div style={{ marginTop: "15px" }}>
                                                                <hr className="my-1" />
                                                                <span className="small-text">Choose page</span>
                                                                <div className="Customize-lead">
                                                                    <div className="card-body">
                                                                        <div className="combobox" ref={(node) => this.wrapperRef[1] = node} name="pageManage">
                                                                            <div className="combobox container-input" onClick={this.toggleCollapsePage}>
                                                                                <span className="fa fa-facebook-square" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                                <input type="text" value={pageManage.selected != null ? (pageManage.list.find(element => element.id === pageManage.selected)).name : ""} readOnly
                                                                                    className="test" />
                                                                                <span className="button-icon ion-md-arrow-dropdown"></span>
                                                                            </div>
                                                                            <div className={`listbox d-${pageManage.toggleSelect ? "block" : "none"}`}>
                                                                                <ul>
                                                                                    {pageManage.list.length > 0 ? (
                                                                                        pageManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedFacebookPage))
                                                                                    ) : (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>)}
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                pageManage.isLoading && <div className="justify-content-between" style={{ textAlign: "center" }}><div className="spinner-grow spinner-grow-sm " role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div></div>
                                                            )
                                                    }
                                                    {
                                                        pageManage.selected != null ? (
                                                            <div style={{ marginTop: "15px" }}>
                                                                <hr className="my-1" />
                                                                <span className="small-text">Choose form</span>
                                                                <div className="Customize-lead">
                                                                    <div className="card-body">
                                                                        <div className="combobox" ref={(node) => this.wrapperRef[2] = node} name="formManage">
                                                                            <div className="combobox container-input" onClick={this.toggleCollapseForm}>
                                                                                <span className="fa fa-facebook-square" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                                <input type="text" value={formManage.selected != null ? (formManage.list.find(element => element.id === formManage.selected)).name : ""} readOnly
                                                                                    className="test" />
                                                                                <span className="button-icon ion-md-arrow-dropdown"></span>
                                                                            </div>
                                                                            <div className={`listbox d-${formManage.toggleSelect ? "block" : "none"}`}>
                                                                                <ul>
                                                                                    {formManage.list.length > 0 ? (
                                                                                        formManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedFacebookForm))
                                                                                    ) : (formManage.list.length === 0 ? (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>) : (<div className="justify-content-between" style={{ textAlign: "center" }}><div className="spinner-grow spinner-grow-sm " role="status">
                                                                                        <span className="sr-only">Loading...</span>
                                                                                    </div></div>))}
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }

                                                    {
                                                        (pageManage.selected != null && formManage.selected != null && accountManage != null) && (
                                                            <button className="button-cofirmsubmit" onClick={this.buttonCofirm}>
                                                                <span>Continue</span>
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                    <button className="button-cofirmsubmit" onClick={this.buttonReverseFacebook}>
                                                        <span><span className="ion-md-arrow-dropleft"></span> &nbsp;Reverse</span>
                                                    </button>
                                                )
                                        }
                                    </div>


                                </div>
                                {
                                    /* end facebook component*/
                                }
                                {
                                    (confirmDataFacebook) && (<div className="card-body">
                                        <div className="card card-tab" style={{ textAlign: "center" }}>
                                            <h5 className="card-title" style={{ textAlign: "left" }}>Test data Facebook</h5>
                                            {
                                                (!testFormSuccess) ? (
                                                    <button className="button-testData" onClick={this.buttonTestFormFacebook} style={{ marginBottom: "10px" }}>
                                                        <span><span className="ion-md-arrow-dropright-circle"></span> Test data</span>
                                                    </button>
                                                ) : (
                                                        (testFormSuccess === "loading") ? (
                                                            <div className="text-center">
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div>
                                                                    <button className="button-testData" onClick={this.buttonTestFormFacebook} style={{ marginBottom: "10px" }}>
                                                                        <span><span className="ion-md-arrow-dropright-circle"></span> Test Done</span>
                                                                    </button>
                                                                    <div className="card card-tab" style={{ width: "90%" }}>
                                                                        {formFieldManage.map(element =>
                                                                            (
                                                                                <p key={element.id}>{element.id} : <span style={{ color: "black", fontSize: "14px" }}>{element.name}</span></p>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                    )}
                                        </div>
                                    </div>)
                                }

                                {
                                    /* end test component*/
                                }

                                {
                                    (testFormSuccess === true && confirmDataFacebook === true) && (

                                        <div className="card-body" >

                                            <div className="card card-tab">
                                                <h5 className="card-title">Setup Google Action</h5>
                                                <hr className="my-1" />
                                                {
                                                    !confirmDataGoogle ? (
                                                        <div className="card-body" >
                                                            <span>Choose Google sheets Account</span>
                                                            <div className="combobox" ref={(node) => this.wrapperRef[3] = node} name="gaccountManage">
                                                                <div className="combobox container-input" onClick={this.toggleCollapseGAccount}>
                                                                    <span className="fa fa-google" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                    <input type="text" value={gaccountManage.selected != null ? (gaccountManage.list.find(element => element.id === gaccountManage.selected)).name : ""} readOnly
                                                                        className="test" />
                                                                    <span className="button-icon ion-md-arrow-dropdown"></span>
                                                                </div>
                                                                <div className={`listbox d-${gaccountManage.toggleSelect ? "block" : "none"}`}>
                                                                    <ul>

                                                                        {gaccountManage.list.length > 0 ? (
                                                                            gaccountManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedGoogleAccount))
                                                                        ) : (gaccountManage.list.length === 0 ? (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>) : (<div className="justify-content-between" style={{ textAlign: "center" }}><div className="spinner-grow spinner-grow-sm " role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div></div>))}
                                                                        <li>
                                                                            <div className="listbox-create-facebook" onClick={this.openGoogleLoginWindow}>
                                                                                <span>+ Add new Account</span>
                                                                            </div>
                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                            {
                                                                gaccountManage.selected != null ? (
                                                                    <div style={{ marginTop: "15px" }}>
                                                                        <hr className="my-1" />
                                                                        <span className="small-text">Choose Google SpreadSheet</span>
                                                                        <div className="Customize-lead">
                                                                            <div className="card-body">
                                                                                <div className="combobox" ref={(node) => this.wrapperRef[4] = node} name="googleSheetManage">
                                                                                    <div className="combobox container-input" onClick={this.toggleCollapseDrive}>
                                                                                        <span className="fa fa-google" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                                        <input type="text" value={googleSheetManage.selected != null ? (googleSheetManage.list.find(element => element.id === googleSheetManage.selected)).name : ""} readOnly
                                                                                            className="test" />
                                                                                        <span className="button-icon ion-md-arrow-dropdown"></span>
                                                                                    </div>
                                                                                    <div className={`listbox d-${googleSheetManage.toggleSelect ? "block" : "none"}`}>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <div className="form-group has-search">
                                                                                                    <span className="fa fa-search form-control-feedback"></span>
                                                                                                    <input type="text" className="form-control" placeholder="Search" onKeyUp={event => event.key === "Enter" ? this.handleSearchGoogleSpreadName("searchfile", event) : null} />
                                                                                                </div>
                                                                                            </li>
                                                                                            {googleSheetManage.list.length > 0 ? (
                                                                                                googleSheetManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedGoogleSpreadSheet))
                                                                                            ) : (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>)}
                                                                                        </ul>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        googleSheetManage.isLoading && <div className="justify-content-between" style={{ textAlign: "center" }}><div className="spinner-grow spinner-grow-sm " role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div></div>
                                                                    )
                                                            }
                                                            {
                                                                googleSheetManage.selected != null ? (
                                                                    <div style={{ marginTop: "15px" }}>
                                                                        <hr className="my-1" />
                                                                        <span className="small-text">Choose Worksheet</span>
                                                                        <div className="Customize-lead">
                                                                            <div className="card-body">
                                                                                <div className="combobox" ref={(node) => this.wrapperRef[5] = node} name="sheetManage">
                                                                                    <div className="combobox container-input" onClick={this.toggleCollapseSheet}>
                                                                                        <span className="fa fa-google" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                                        <input type="text" value={sheetManage.selected != null ? (sheetManage.list.find(element => element.id === sheetManage.selected)).name : ""} readOnly
                                                                                            className="test" />
                                                                                        <span className="button-icon ion-md-arrow-dropdown"></span>
                                                                                    </div>
                                                                                    <div className={`listbox d-${sheetManage.toggleSelect ? "block" : "none"}`}>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <div className="form-group has-search">
                                                                                                    <span className="fa fa-search form-control-feedback"></span>
                                                                                                    <input type="text" className="form-control" placeholder="Search" onKeyUp={event => event.key === "Enter" ? this.handleSearchGoogleSpreadName("searchsheet", event) : null} />
                                                                                                </div>
                                                                                            </li>
                                                                                            {sheetManage.list.length > 0 ? (
                                                                                                sheetManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedSheet))
                                                                                            ) : (<div className="justify-content-between" style={{ textAlign: "center" }}><span>Nothing to show</span></div>)}
                                                                                        </ul>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ""
                                                            }

                                                            {
                                                                (gaccountManage.selected != null && googleSheetManage.selected != null && googleSheetManage != null) && (
                                                                    <button className="button-cofirmsubmit" onClick={this.buttonConfirmGoogle}>
                                                                        <span>Continue</span>
                                                                    </button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : (
                                                            <button className="button-cofirmsubmit" onClick={this.buttonReverseGoogle}>
                                                                <span><span className="ion-md-arrow-dropleft"></span> &nbsp;Reverse</span>
                                                            </button>
                                                        )
                                                }
                                            </div>


                                        </div>
                                    )
                                }
                                {
                                    (confirmDataFacebook && confirmDataGoogle && !this.state.testData) && (
                                        <div className="card-body" >

                                            <div className="card card-tab">
                                                <h5 className="card-title">Setup Data Append in Google sheet</h5>
                                                {sampleField.map(element => this.renderInputDroplist(element))}

                                                <Form.Check type="switch" id="custom-switch" label="Auto send Email" onChange={this.handleSwitch.bind(this)} />
                                                <br />
                                                {
                                                    (this.state.isSendEmail && (
                                                        <div>
                                                            {
                                                                this.state.sampleEmailField.map(element => this.renderInputDroplistEmail(element))
                                                            }
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    (sampleField.length > 0) ? (<button className="button-cofirmsubmit" onClick={this.testData.bind(this)}>


                                                        <span>&nbsp;Test Data &nbsp;<span className="ion-md-fastforward"></span> </span>
                                                    </button>) : (<div>No data found in Sheet</div>)
                                                }

                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (confirmDataFacebook && confirmDataGoogle && this.state.testData) && (
                                        <div className="card-body" >

                                            <div className="card card-tab">
                                                <h5 className="card-title">Confirm Data</h5>
                                                {
                                                    (!this.state.isCreated) ? (<button className="button-cofirmsubmit" onClick={this.submitData.bind(this)} >


                                                        <span>&nbsp;Confirm Data &nbsp;<span className="ion-md-done-all"></span> </span>
                                                    </button>) : (
                                                            <button className="button-cofirmsubmit-disable" disabled={true}>
                                                                <span>&nbsp;Confirm Data &nbsp;<span className="ion-md-done-all"></span> </span>
                                                            </button>)
                                                }

                                            </div>


                                        </div>)
                                }
                            </div>)
                        }
                    </div>


                </div>) : (<div className="container-content">Loading data <div className="spinner-grow spinner-grow-sm " role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>)}
            </div>
        )
    }
}

export default Edit;