import React, { Component } from 'react';
import Cookies from "js-cookie";
import Notification from "../Notification";


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            showNavBar: false,
            width: window.innerWidth,
            height: window.innerHeight
        };

    }
    toggleCollapse = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };

    logout = (e) => {
        Cookies.set("_uid", -1, { expires: 0, path: '/' });
        Cookies.set("_tkl", -1, { expires: 0, path: '/' });
        window.location.reload();
    }

    updateDimensions = () => {
        if (window.innerWidth < 992) {
            this.setState({
                showNavBar: true
            })
        } else {
            this.setState({
                showNavBar: false
            })
        }
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        if (window.innerWidth > 992) {
            this.setState({
                showNavBar: false
            })
        } else {
            this.setState({
                showNavBar: true
            })
        }
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }



    render() {
        const listRoute = [
            {
                id: 1,
                name: "Home",
                path: "/",
            },
            {
                id: 2,
                name: "Profile",
                path: "/Profile",
            },
            {
                id:3,
                name: "Logout",
                path: "/#",
                onClick: this.logout.bind(this)
            }
        ];

        return (

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleCollapse}>
                        <i className="fa fa-bars" />
                    </button>
                    <div className={`collapse navbar-collapse ${!this.state.collapseOpen ? "" : "show"}`} id="navbarSupportedContent">
                        <ul className="nav navbar-nav ml-auto">

                            <Notification />

                            {this.state.width < 992 ? (
                                <div>
                                    {listRoute.map((item) => (
                                        <a className="nav-link" key={item.id} style={{ cursor: "pointer" }} href={item.path} onClick ={(item.onClick && item.onClick)}>{item.name}</a>
                                    ))}
                                </div>
                            ) : (
                                    <div className="d-flex">
                                        <li className="nav-item">
                                            <a className="nav-link" style={{ cursor: "pointer" }} href="/Profile">Profile</a>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link" style={{ cursor: "pointer", border: "none", background: "none" }} onClick={this.logout.bind(this)}>Log out</button>
                                        </li>

                                    </div>
                                )}

                        </ul>
                    </div>
                </div>
            </nav>


        )
    }
}

export default Navbar;