
import Cookies from 'js-cookie';

export const ROOT = "https://meai.vn";
export const CHAT_ROOT = "https://meai.vn/chat";
// export const ROOT = "http://localhost:3006";

export const Admin_Id = ["e658b9b8-fa56-4319-9089-cfad837b0a98","836557ec-d6c0-40cd-a388-0886d16e99a5","2f81f8bf-a638-4fb5-98ee-66ac12c48f82"]
export const isAdmin = () => {
    const user_id = Cookies.get("_uid");
    return Admin_Id.includes(user_id);
}