import React, { Component } from 'react';
import Navbar from "../Navbar";
import * as methodTypes from "../../Constants/method.constants";
import HookAPI from "../../utility/HookAPI";
import message from "@davistran86/notification"

export default class CreateCommentSetting extends Component {
    constructor() {
        super();
        this.state = {
            accountManage: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            showPanelChooseForm: [],
            trueLink: false,
            isCreated: false,
            selectedPostId: -1,
            selectedPageId: -1,
            isHiddenComment: false,
            isGetPhoneNumber: false,
            app_name: false
        }
        this.wrapperRef = [];
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    setupToggleOff = () => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (typeof object[1] === "object") {
                object[1].toggleSelect = false;
                this.setState({
                    object: {
                        ...object[1],
                    }
                });
            }
        })
    }

    setupToggle = (data, toggle) => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (typeof object[1] === "object") {
                if (object[0] === data) {
                    object[1].toggleSelect = toggle;

                    this.setState({
                        object: {
                            ...object[1]
                        }
                    })
                } else {
                    object[1].toggleSelect = false;
                    this.setState({
                        object: {
                            ...object[1],
                        }
                    });
                }

            }
        })
    }

    toggleCollapseAccount = () => {
        const { accountManage } = this.state;
        this.setupToggle("accountManage", !accountManage.toggleSelect);
        if (accountManage.toggleSelect) {
            HookAPI("", "FacebookService/{userid}", methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        accountManage: {
                            selected: accountManage.id,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: this.state.accountManage.toggleSelect
                        }
                    })
                } else {
                    this.setState({
                        accountManage: {
                            selected: null,
                            list: [],
                            isLoading: false,
                            toggleSelect: this.state.accountManage.toggleSelect
                        }
                    })
                }
            }
            )
        }


    };
    renderInfoInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id, e)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.find(el => (el != null && el.contains(event.target)))) {
            this.setupToggleOff();
        }

    }

    inputPostLink = (e) => {
        // https://www.facebook.com/phatnguyen.307/posts/1042335566285566

        let postLink = e.target.value;
        let listId = postLink.match(/\d{12,16}/g);
        if (listId != null && listId.length < 2) {
            message.error({ title: "Meai Notification", description: "Wrong link, please copy a link have 2 Id, pageId and postId" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            });
            this.setState({
                trueLink: false
            })
        }
        if (listId !== null && listId.length === 2) {
            const pageId = listId[1];
            const postId = listId[0];
            let dataPrepare = {};
            dataPrepare.facebookPageId = pageId;
            dataPrepare.facebookPostId = postId;
            dataPrepare.faccountId = this.state.accountManage.selected;

            HookAPI(dataPrepare, "web/{userid}/hidecomment", methodTypes.POST).then((response) => {
                this.setState({
                    trueLink: false,
                    selectedPageId: -1,
                    selectedPostId: -1
                })
                if (response.status === 200) {
                    this.setState({
                        trueLink: true,
                        selectedPageId: pageId,
                        selectedPostId: postId
                    })
                }
            });

        }


    }

    selectedFacebookAccount = (id) => {
        const { accountManage } = this.state;
        this.setState({
            accountManage: {
                selected: id,
                list: accountManage.list,
                isLoading: accountManage.isLoading,
                toggleSelect: false
            }
        });
        // if (accountManage.selected !== id) {
        //     HookAPI("", "FacebookService/{userid}/" + id).then((response) => {


        //     })
        // }
    }
    handleChangeSetup = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmitData = () => {
        const { accountManage, selectedPageId, selectedPostId, isHiddenComment, isGetPhoneNumber, app_name } = this.state;
        let dataPrepare = {};
        dataPrepare.facebookPageId = selectedPageId;
        dataPrepare.facebookPostId = selectedPostId;
        dataPrepare.isHideComment = isHiddenComment;
        dataPrepare.isGetPhone = isGetPhoneNumber;
        dataPrepare.hide_comment_name = app_name;
        dataPrepare.faccount_id = accountManage.selected;
        this.setState({
            isCreated: true
        })
        HookAPI(dataPrepare, "web/{userid}/hidecomment/Create", methodTypes.POST).then((response) => {

            if (response.status !== 200) {
                this.setState({
                    isCreated: false
                })
            }
        });

    }

    openFacebookLoginWindow = () => {
        window.open(this.state.URL_LOGIN_FACEBOOK, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + (window.innerHeight - 640) / 2 + ",left=" + (window.innerWidth - 640) / 2 + ",width=640,height=640");
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillMount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        HookAPI(null, "web/{userid}/hidecomment/Create").then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    URL_LOGIN_FACEBOOK: response.data.data.URL_LOGIN_FACEBOOK,
                    URL_LOGIN_GOOGLE: response.data.data.URL_LOGIN_GOOGLE
                })
            }
        })
    }
    addAppName = (event) => {
        this.setState({
            app_name: event.target.value
        })
    }

    removeAppName = () => {
        this.setState({
            app_name: false
        })
    }

    render() {
        const { accountManage, trueLink } = this.state;
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />

                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            Setup
                            {
                                (this.state.app_name) && (
                                    <div style={{ display: "flex" }}>
                                        <p style={{ display: "flex" }}>Name: {this.state.app_name}</p>
                                        <span onClick={this.removeAppName.bind(this)} style={{ fontSize: 14, cursor: "pointer", marginLeft: 5 }}><i className="ion-md-create"></i></span>
                                    </div>
                                )
                            }
                        </h3>

                    </div>

                    <div className="card card-init-css" >
                        {(!this.state.app_name) && (<div className="card-body" >

                            <div className="card card-tab">
                                <h5 className="card-title">Named your app!</h5>
                                <div className="combobox">
                                    <div className="combobox container-input">
                                        <span className="button-icon ion-md-clipboard" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1, paddingTop: 0 }}></span>
                                        <input type="text"
                                            onKeyUp={event => event.key === "Enter" ? this.addAppName(event) : null}
                                            className="test" aria-describedby="nameHelp" />
                                        <span className="button-icon ion-md-checkbox-outline" style={{ position: "relative", opacity: 1, paddingTop: 0 }}></span>
                                    </div>
                                    <small id="nameHelp" className="form-text text-muted">Press Enter to submit.</small>
                                </div>

                            </div>


                        </div>)}
                        {this.state.app_name && (
                            <div className="card card-tab">
                                <h5 className="card-title">Choose facebook Account</h5>
                                <div className="card-body" >
                                    <span>Choose Facebook lead account</span>
                                    <div className="combobox" ref={(node) => this.wrapperRef[0] = node} name="accountManage">
                                        <div className="combobox container-input" onClick={this.toggleCollapseAccount}>
                                            <span className="fa fa-facebook-square" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                            <input type="text" value={accountManage.selected != null ? (accountManage.list.find(element => element.id === accountManage.selected)).name : ""} readOnly
                                                className="test" />
                                            <span className="button-icon ion-md-arrow-dropdown"></span>
                                        </div>
                                        <div className={`listbox d-${accountManage.toggleSelect ? "block" : "none"}`}>
                                            <ul>
                                                {accountManage.list.length > 0 ? (
                                                    accountManage.list.map((account) => this.renderInfoInDroplist(account, this.selectedFacebookAccount))
                                                ) :
                                                    (
                                                        <div>
                                                            <div className="justify-content-between" style={{ textAlign: "center" }}>
                                                                {accountManage.list.length === 0 ? (

                                                                    <div className="" role="status">
                                                                        <span>No data</span>
                                                                    </div>
                                                                ) : (
                                                                        <div className="spinner-grow spinner-grow-sm " role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <li>
                                                    <div className="listbox-create-facebook" onClick={this.openFacebookLoginWindow}>
                                                        <span>+ Add new Account</span>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}
                        {accountManage.selected && this.state.app_name && (
                            <div className="card card-tab">
                                <h5 className="card-title">Input facebook post link</h5>
                                <div className="card-body" >
                                    <span>Input post link</span>
                                    <div className="combobox" ref={(node) => this.wrapperRef[0] = node} name="accountManage">
                                        <div className="combobox container-input" >
                                            <span className="fa fa-facebook-square" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                            <input type="text" className="test" onChange={this.inputPostLink.bind(this)} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}
                        {(trueLink && accountManage.selected && this.state.app_name) && (
                            <div className="card card-tab">
                                <h5 className="card-title">Setup comment action</h5>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" name="isHiddenComment" onChange={this.handleChangeSetup.bind(this)} />
                                    <label className="custom-control-label" htmlFor="customSwitch1">Hide comment</label>
                                </div>

                                <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch2" name="isGetPhoneNumber" onChange={this.handleChangeSetup.bind(this)}/>
                                        <label className="custom-control-label" htmlFor="customSwitch2" >get Phonenumber</label>
                                    </div>
                                <hr />

                                {
                                    (!this.state.isCreated) ? (<button className="button-cofirmsubmit" onClick={this.handleSubmitData.bind(this)}>


                                        <span>&nbsp;Submit Data &nbsp;<span className="ion-md-done-all"></span> </span>
                                    </button>) : (
                                            <button className="button-cofirmsubmit-disable" disabled={true}>
                                                <span>&nbsp;Submit Data &nbsp;<span className="ion-md-done-all"></span> </span>
                                            </button>)
                                }

                            </div>
                        )}
                    </div>

                </div>
            </div>
        )
    }
}