import React, { Component } from 'react';
import Navbar from "../Navbar";
import { Table } from "react-bootstrap";
import HookAPI from "../../utility/HookAPI";
import * as methodTypes from '../../Constants/method.constants';

class PhoneCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCollection: []
        }
    }



    componentDidMount() {
        HookAPI(null, "web/{userid}/hidecomment/List/Phone").then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    phoneCollection: response.data.data
                })
            }
        })
    }


    render() {
        const { phoneCollection } = this.state;
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            List of FliG
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab">
                            {
                                phoneCollection.length > 0 ?
                                    (
                                        <div className="card-hover" style={{ display: "block" }}>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User name</th>
                                                        <th>Phone number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        phoneCollection.map((element, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{element.name}</td>
                                                                <td>{element.phone.map((el,id) => (<p key={id}>{el}</p>))}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </Table>


                                            <hr />
                                        </div>
                                    ) : (<h3>Not thing to show</h3>)
                            }
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default PhoneCollection;