import React, { Component } from 'react';
import Navbar from "../Navbar";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listApp: []
        }
    }


    rowLinking = (data) => {
        return (
            <div key={data.id}>
                <div className={`card row ${data.isActive ? "row-success" : "row-danger"}`} style={{ width: '18rem' }}>
                    <div className="card-body">
                        <h5 className="card-title">{data.title}</h5>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">Dashboard</h3>
                    </div>
                    <div className="card card-init-css" >


                        <div className="card card-tab">
                            <h5 className="card-title" style={{ margin: "0" }}>Manage FliG</h5>
                            <small id="emailHelp" className="form-text text-muted">Create and manage your leadgen facebook on only one app.</small>
                            <hr />
                            <div className="card-body ">

                                <div className="card-content" style={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <span className="align-middle mr-1"><a href="/Create" className="btn btn-info ">Create <span className="ion-md-add"></span></a></span>
                                    <span className="align-middle"><a href="/List" className="btn btn-info ">List <span className="ion-md-list"></span></a></span>
                                </div>


                            </div>

                        </div>
                        <div className="card card-tab">
                            <h5 className="card-title" style={{ margin: "0" }}>Manage Comment</h5>
                            <small id="emailHelp" className="form-text text-muted">hide and show comments on your posts whenever have a comment</small>
                            <hr />
                            <div className="card-body " >

                                <div className="card-content" style={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <span className="align-middle mr-1"><a href="/CommentSetting" className="btn btn-info ">Create <span className="ion-md-add"></span></a></span>
                                    <span className="align-middle"><a href="/ListComment" className="btn btn-info ">List <span className="ion-md-list"></span></a></span>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default Dashboard;