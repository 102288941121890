import React, { Component } from "react";
import HookAPI from "../../utility/HookAPI";
import message from "@davistran86/notification";
import * as methodTypes from "../../Constants/method.constants";
import Cookies from "js-cookie";
import { ROOT } from "../../Constants/Config.constants";

export default class SubmitPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            re_password: ""
        }
    }

    handleChange = (e) => {
        var changeState = e.target.name;
        this.setState({
            [changeState]: e.target.value
        })
    }

    handleSubmit = (e) => {
        let dataPrepare = Object();

        if (this.state.password.length < 10) {
            message.warning({ title: "Error when change password", description: "Password must be longer than 10 characters" });
            return;
        }

        if (this.state.password !== this.state.re_password) {
            message.warning({ title: "Create account failed", description: "your password and re-password are not the same!!!" });
            return;
        }
        dataPrepare.password = this.state.password;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        HookAPI(dataPrepare, "users/forgotpassword/reset?token=" + params.get("token"), methodTypes.POST).then((response) => {
            let data = response.data;
            if (data.status === 200) {
                if (data.data.cookies !== undefined) {
                    for (const [key, value] of Object.entries(data.data.cookies)) {
                        Cookies.set(key, value, { expires: 7, path: '/' });
                    }
                }
                this.setState({
                    signUpSuccess: true
                })
            } else {
                this.setState({
                    signUpSuccess: false
                })
            }
        });
    }

    render() {
        if (this.state.signUpSuccess) {
            window.location.replace(ROOT);

        } else {
            return (
                <form>
                    <h3>Change Password</h3>

                    <div className="form-group">
                        <label>New password</label>
                        <input type="password" className="form-control" placeholder="Enter new password" name="password" onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="form-group">
                        <label>Re - new password</label>
                        <input type="password" className="form-control" placeholder="re-enter new password" name="re_password" onChange={this.handleChange.bind(this)} />
                    </div>

                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    <div style={{ display: "inline-block" }}>
                    </div>

                </form>
            );

        }
    }
}