import React, { Component } from 'react';
import Navbar from "../Navbar";
import HookAPI from "../../utility/HookAPI";
import { Table, Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap'

class ListFliGData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }



    componentDidMount() {
        HookAPI(null, "data/{userid}/list").then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    listData: response.data.data
                })
            }
        })
    }


    render() {
        const { listData } = this.state;
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            List of Data
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab" style={{ width: "fit-content" }}>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Campain name</th>
                                        <th>Group name</th>
                                        <th>Data of User</th>
                                        <th>Sharing for</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listData.length > 0 ?
                                            listData.map(element => (
                                                <>
                                                    <tr key={element.id}>
                                                        <th scope="row">{element.id}</th>
                                                        <td>{element.rawData.campainName}</td>
                                                        <td>{element.rawData.adsetName}</td>
                                                        <td colSpan={element.sharingFor !== null ? 1 : 2}>{element.user.name}</td>
                                                        {
                                                            element.sharingFor !== null && (

                                                                <td>{element.sharingFor.name}</td>
                                                            )
                                                        }
                                                        <td><Button outline color='info' id={`collapHandle-${element.id}`}>Detail</Button></td>
                                                    </tr>
                                                    <tr key={`data-${element.id}`}>
                                                        <td colSpan={5}>
                                                            <UncontrolledCollapse toggler={`collapHandle-${element.id}`}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <Table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Ad name</th>
                                                                                    <td>{element.rawData.adName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Ad set name</th>
                                                                                    <td>{element.rawData.adsetName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Advertise name</th>
                                                                                    <td>{element.rawData.advertiseName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>campain name</th>
                                                                                    <td>{element.rawData.campainName}</td>
                                                                                </tr>

                                                                                {
                                                                                    Object.entries(element.rawData.field).map((el, id1) => (
                                                                                        <tr key={id1}>
                                                                                            <th>{el[0]}</th>
                                                                                            <td>{(el[1].toString()).replace(",", " ").replace("[", "").replace("]", "")}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                }



                                                                            </tbody>
                                                                        </Table>
                                                                    </CardBody>
                                                                </Card>
                                                            </UncontrolledCollapse>

                                                        </td>

                                                    </tr>
                                                </>
                                            )) :
                                            (
                                                <tr>
                                                    <td colSpan={5}>Not thing to show!</td>
                                                </tr>
                                            )
                                    }

                                </tbody>
                            </Table>
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default ListFliGData;