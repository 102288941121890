import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router';
import {
    Button, UncontrolledPopover,
    PopoverHeader, PopoverBody, Badge, Toast, ToastHeader, ToastBody
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

import HookAPI from "../../utility/HookAPI";


// function handleClickNotification() {
//     const history = useHistory();

//     const routeChange = () => {
//         let path = `newPath`;
//         history.push(path);
//     }
// }

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listNotification: [],
            pageIndex: 1
        }
    }
    componentDidMount() {
        HookAPI(null, "notification/{userid}").then(res => {
            if (res.data.status == 200) {
                this.setState({
                    listNotification: res.data.data
                })
            }
        })
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.setState({
                pageIndex: this.state.pageIndex + 1
            })
        }
    }

    handleClickNotification = (el) => {
        HookAPI("", "notification/{userid}/" + el.id)

        if (5 === el.notificationType) {
            this.props.history.push("/FliGData")

        } else if (6 === el.notificationType) {

            this.props.history.push("/FliGShareData")
        }
    }



    render() {
        const { listNotification, pageIndex } = this.state;
        const showList = listNotification.slice(0, 5 * pageIndex);
        const colorOfType = [
            {
                type: [1, 2, 3],
                color: "danger"
            },
            {
                type: [4, 5, 7],
                color: "info",
            },
            {
                type: [6],
                color: "success"
            }
        ]
        return (
            <div>
                <Button id="UncontrolledPopover" type="button" style={{ border: "unset", backgroundColor: "unset", color: "rgba(0,0,0,.5)" }}>
                    <span className={`ion-md-notifications`} />{listNotification.length > 0 ? <Badge color="primary" pill>{listNotification.length}</Badge> : null}
                </Button>
                <UncontrolledPopover placement="bottom" target="UncontrolledPopover" trigger="legacy" >
                    <PopoverHeader>Notification</PopoverHeader>
                    <PopoverBody style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden", height: "350px" }} onScroll={this.handleScroll}>

                        {
                            showList.map((element, index) => (
                                <Toast key={index} onClick={this.handleClickNotification.bind(this, element)}>
                                    <ToastHeader icon={colorOfType.find(el => el.type.some((elm) => elm === element.notificationType)).color}>{element.title}</ToastHeader>
                                    <ToastBody >{element.message}</ToastBody>
                                </Toast>
                            ))
                        }
                    </PopoverBody>
                </UncontrolledPopover>
            </div>)
    }
}

export default withRouter(Notification)