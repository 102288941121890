import React, { Component } from 'react';
import Navbar from "../Navbar";
import { Row, Container } from "react-bootstrap";
import HookAPI from "../../utility/HookAPI";
import * as methodTypes from '../../Constants/method.constants';

class ListCreated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listAppCreate: []
        }
    }



    componentDidMount() {
        HookAPI(null, "web/{userid}" + this.props.location.pathname).then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    listAppCreate: response.data.data
                })
            }
        })
    }

    handleClickDelete = (id) => {
        const { listAppCreate } = this.state;
        const deleteId = id;
        let confirmRemove = window.confirm('Confirm Delete');
        if (confirmRemove) {
            HookAPI(null, "web/{userid}/delete/"+id,methodTypes.DELETE).then((response) => {
                if (response.data.status === 200) {
                    let deleteIndex = listAppCreate.findIndex(element => element.id === id);
                    listAppCreate.splice(deleteIndex, 1);

                    this.setState({
                        listAppCreate: listAppCreate
                    })
                }
            })
        }

    }

    render() {
        const { listAppCreate } = this.state;
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />
                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            List of FliG
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab">
                            {
                                listAppCreate.length > 0 ?
                                    listAppCreate.map(element => (
                                        <div key={element.id} className="card-hover" style={{ display: "block" }}>
                                            <Container >
                                                <Row>
                                                    <h6>FliG name: {element.lslName}</h6>
                                                </Row>
                                                <Row>
                                                    <h6 className="card-title component-header">{element.faccount.name} <i> (facebook) &nbsp;</i></h6>
                                                    <span className="ion-ios-arrow-round-forward"></span>
                                                    <h6 className="card-title component-header"> &nbsp;  {element.gaccount.name}<i> (google)</i></h6>
                                                </Row>
                                                <Row style={{ justifyContent: "space-between" }}>
                                                    <a href={`./Edit/${element.id}`}>
                                                        <button className="button-editLSL">
                                                            <span><span className="ion-ios-create"></span> &nbsp;Edit</span>
                                                        </button>
                                                    </a>
                                                        <button className="button-deleteLSL" onClick={this.handleClickDelete.bind(this,element.id)}>
                                                            <span><span className="ion-md-close"></span> &nbsp;Delete</span>
                                                        </button>
                                                </Row>
                                            </Container>


                                            <hr />
                                        </div>
                                    )) : (<h3>Not thing to show</h3>)
                            }
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default ListCreated;