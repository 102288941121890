import React, { Component } from "react";
import HookAPI from "../../utility/HookAPI";
import Cookies from "js-cookie";
import * as methodTypes from "../../Constants/method.constants";
import { ROOT } from "../../Constants/Config.constants";

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loginSuccess: false,
        }
    }

    handleChange = (e) => {
        var changeState = e.target.name;
        this.setState({
            [changeState]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        let dataPrepare = Object();
        dataPrepare.userEmail = email;
        dataPrepare.userPassword = password;
        HookAPI(dataPrepare, "users/login", methodTypes.POST).then((response) => {
            let data = response.data;
            if (data.success) {
                if (data.data.cookies !== undefined) {
                    for (const [key, value] of Object.entries(data.data.cookies)) {
                        Cookies.set(key, value, { expires: 7, path: '/' });
                    }
                }
                this.setState({
                    signUpSuccess: true
                })
            } else {
                this.setState({
                    signUpSuccess: false
                })
            }
        })

    }

    render() {
        if (this.state.signUpSuccess) {
            window.location.replace(ROOT);

        } else {
            return (
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <h3>Sign In</h3>

                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" className="form-control" placeholder="Enter email" name="email" onChange={this.handleChange.bind(this)} />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.handleChange.bind(this)} />
                    </div>

                    {/* <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div> */}

                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    {/* <p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p> */}
                    <div style={{ display: "inline-block" }}>
                        <p className="forgot-password text-left">
                            <a href="./sign-up">Register</a>
                        </p>
                        <p className="forgot-password text-right">
                            <a href="./forgot">Forgot password?</a>
                        </p>
                    </div>

                </form>
            );
        }


    }
}