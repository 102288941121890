import React, { useState, useEffect } from 'react';
import HookAPI from '../../utility/HookAPI';
import Navbar from '../Navbar'
import { Card, CardImg, CardBody, CardTitle, Spinner, List, ListInlineItem, Button } from "reactstrap"
import { CHAT_ROOT } from '../../Constants/Config.constants';
import { DELETE, POST } from '../../Constants/method.constants';
import { Link, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';


const ListPageActive = (props) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [listPage, setListPage] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (!pageLoaded) {
            HookAPI("", `PageManage/{userid}`).then(res => {
                const data = res.data;
                if (data.status === 200) {
                    setListPage(data.data);
                }
                setPageLoaded(true)
            })
        }
    }, [pageLoaded])

    const removeConnectedPage = (pageId, index) => {
        confirmAlert({
            title: 'Do you want unlink this page',
            message: 'If you accept this action. All data about Conversation, setting. .v.v. Will be remove!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        HookAPI("", `PageManage/{userid}/${pageId}`, DELETE).then(res => {
                            if (res.status === 200) {
                                let list = listPage;
                                list = list.filter(el => el.pageId !== pageId);
                                setListPage([...list])
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return; }
                }
            ]
        });

    }

    const goPage = (id) => {
        window.location.href = `https://meai.vn/chat/${id}`;
    }

    return (
        <div id="content" className="p-4 p-md-5">
            <Navbar />
            <div className="container-content">
                <div className="card-hover">
                    <h3 className="card-title component-header">
                        Active Messenger
                        </h3>
                </div>
                <div className="card card-init-css" >
                    {

                        pageLoaded ? (

                            <div className="card card-tab" style={{ width: "max(768px,75%)" }}>
                                <h5 className="card-title">Setup</h5>
                                <div className="card-body">
                                    <h6 >Active Page</h6>
                                    <div className={`row page-container active-container`}>
                                        {
                                            listPage.map((page, index) => (
                                                <div key={`page-item activated-page-${page.id}-${index}`} className={`col-3`}>
                                                    <Card className={`page-item`}>
                                                        <CardImg top src={`${page.pageLogo}`} alt={`logo of page: ${page.pageName}`} className={"page-image mx-auto mt-3"} />
                                                        <CardBody>
                                                            <CardTitle tag="h6" className={"page-name"}>{page.pageName}</CardTitle>
                                                        </CardBody>
                                                        <List type="inline" className="px-3">

                                                            <ListInlineItem>
                                                                <Button color={"info"} outline size={"sm"} onClick={() => goPage(page.id)}>Go to Chat</Button>
                                                            </ListInlineItem>
                                                            <ListInlineItem>
                                                                <Link to={`/Pagemanager/${page.id}`}><Button color={"primary"} outline size={"sm"} >Edit</Button></Link>
                                                            </ListInlineItem>
                                                            <ListInlineItem>
                                                                <Button color={"danger"} outline size={"sm"} onClick={() => removeConnectedPage(page.pageId, index)}>Remove</Button>
                                                            </ListInlineItem>
                                                        </List>
                                                    </Card>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>

                        ) : (
                            <Spinner color={"info"} className={`m-auto`} />
                        )
                    }
                </div>


            </div>
        </div >
    )
}
export default ListPageActive;