import React, { useEffect, useRef, useState } from 'react';
import Navbar from "../Navbar";
import * as methodTypes from "../../Constants/method.constants";
import HookAPI from "../../utility/HookAPI";
import { Col, FormGroup, Label, Input, Form, UncontrolledTooltip, Button, CardFooter } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaQuestionCircle } from 'react-icons/fa'


const UserShareRow = (props) => {
    const [data] = useState(props.data);
    return (

        <Form className="shadow p-3 bg-body rounded">
            <FormGroup row>
                <Label for={`exampleName${data.id}`} sm={5} >Name</Label>
                <Col sm={7}>
                    <Input type="text" readOnly defaultValue={data.user.name} id={`exampleName${data.id}`} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for={`exampleEmail${data.id}`} sm={5} >Email</Label>
                <Col sm={7}>
                    <Input type="text" readOnly defaultValue={data.user.email} id={`exampleEmail${data.id}`} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for={`exampleMinute${data.id}`} sm={5} >Share minute <FaQuestionCircle id={`unControlledTooltip-${data.user.id}`} size={14} /></Label>
                <UncontrolledTooltip placement="right" target={`unControlledTooltip-${data.user.id}`} >
                    Decision minute share for this person
      </UncontrolledTooltip>
                <Col sm={7}>
                    <Input type="number" id={`exampleMinute${data.id}`} onChange={(e) => props.setMinuteUser(data.id, e.target.value)} min={1} defaultValue={data.minute} />
                </Col>
            </FormGroup>
            <Button className="float-right" onClick={() => props.remove(data.id)} color={`danger`} size="sm">Remove</Button>
        </Form>
    )
}


const CreateShareData = (props) => {

    const wrapperRefUser = useRef();
    const wrapperRefProject = useRef();

    const [pageLoaded, setPageLoaded] = useState(false);
    const [userPanelLoaded, setUserPanelLoaded] = useState(false);
    const [projectPanelLoaded, setProjectPanelLoaded] = useState(false);
    const [selectedProject, setSelectedProject] = useState(-1);
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [createList, setCreateList] = useState([]);
    const [showUserPanel, setShowUserPanel] = useState(false);
    const [showProjectPanel, setShowProjectPanel] = useState(false);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRefUser.current && !wrapperRefUser.current.contains(event.target)) {
                setShowUserPanel(false);
            }
            if (wrapperRefProject.current && !wrapperRefProject.current.contains(event.target)) {
                setShowProjectPanel(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRefUser, wrapperRefProject]);

    const renderInforLSLInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id)}>
                <div>
                    <span className="name">{data.lslName ? data.lslName : "Unknown"}</span>

                </div>

            </li>
        )
    }

    const renderInfoInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }

    const addCreateList = (userId) => {

        var list = [...createList];
        list.push({ id: userId, user: users.find(el => el.id === userId), minute: null });
        setCreateList(list);
    }

    const searchValue = (e) => {
        setShowUserPanel(true);
        setUserPanelLoaded(true);
        if (e.target.value !== null && e.target.value !== "") {
            HookAPI(null, "/web/{userid}/search/" + e.target.value, methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    setUsers(response.data);
                    setUserPanelLoaded(false);
                }
            });
        } else {
            setUserPanelLoaded(false);
            setShowUserPanel(false);
        }
    }

    const setMinuteUser = (dataId, value) => {
        let list = createList;
        if (list.find(el => el.id === dataId)) {
            let index = list.findIndex(el => el.id === dataId);
            list[index].minute = value;
            setCreateList(list);
        }
    }
    const removeFromCreateList = (dataId) => {
        let list = createList;
        if (list.find(el => el.id === dataId)) {
            list.splice(list.findIndex(el => el.id === dataId), 1);
            // console.log(list);
            setCreateList([...list]);
        }
    }

    useEffect(() => {
        HookAPI("", `datasharing/{userid}/list/all`, methodTypes.GET, { project: `${selectedProject > 0 ? selectedProject : null}` }).then((response) => {
            response = response.data;
            if (response.status === 200) {

                let list = [];
                response.data.forEach((item, index) => {
                    list.push({ id: item.id, user: item.sharedUser, minute: item.minute })
                })

                setCreateList(list);
            }
        })
        setShowProjectPanel(false);
    }, [selectedProject])

    useEffect(() => {
        if (pageLoaded == false) {
            HookAPI("", "web/{userid}/List").then((response) => {
                response = response.data;
                if (response.status === 200) {
                    let list = response.data;
                    list.push({ id: -1, lslName: "Share all" })
                    setProjects(list);
                }
            })
        }

        const listQueryParam = new URLSearchParams(props.location.search);
        if (listQueryParam) {

            const projectSelected = listQueryParam.get("project");
            if (projectSelected > 0) {

                setSelectedProject(listQueryParam.get("project"))
            } else {
                setSelectedProject(-1);
            }

        }

    }, [pageLoaded])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const currPos = result.source.index;
        const newPos = result.destination.index;

        var list = createList;

        const currData = createList[currPos];
        const newData = createList[newPos];
        list[currPos] = newData;
        list[newPos] = currData;

        setCreateList(list);
    }

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        with: "250px !important",

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        padding: grid,
        width: 250
    });

    const saveData = () => {
        let prepareData = [];
        createList.forEach((item, index) => {
            let obj = {
                id: Number.isInteger(item.id) ? item.id : null,
                userId: item.user.id,
                minute: parseInt(item.minute)
            }
            prepareData.push(obj);
        })
        HookAPI(prepareData, "datasharing/{userid}/save/all", methodTypes.POST, { project: selectedProject })
    }

    return (
        <div id="content" className="p-4 p-md-5">
            <Navbar />

            <div className="container-content">
                <div className="card-hover">
                    <h3 className="card-title component-header">
                        Add sharing user
                        </h3>

                </div>

                <div className="card card-init-css" >


                    <div className="card card-tab">
                        <h5 className="card-title">Setup</h5>
                        <div className="card-body">
                            <span className="small-text">Choose share project data</span>

                            <small id="nameHelp" className="form-text text-muted">If not choose any project. application auto share all data you have</small>
                            <div className="Customize-lead">
                                <div className="card-body">
                                    <div className="combobox" ref={wrapperRefProject} name="user">
                                        <div className="combobox container-input" onClick={() => setShowProjectPanel(true)}>
                                            <span className="fa fa-cubes" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                            <input type="text" placeholder={"Click to choose project"} readOnly={true} defaultValue={projects.find(el => el.id === selectedProject) ? projects.find(el => el.id == selectedProject).lslName : ""} />
                                            <span className="button-icon ion-md-arrow-dropdown"></span>
                                        </div>
                                        <div className={`listbox d-${showProjectPanel ? "block" : "none"}`}>
                                            <ul>
                                                {(
                                                    projects.map((item) => renderInforLSLInDroplist(item, setSelectedProject))
                                                )}
                                            </ul>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {
                                selectedProject !== null && (
                                    <div className="card-body">
                                        <span className="small-text">Choose user</span>

                                        <small id="nameHelp" className="form-text text-muted">can choose multiple user</small>
                                        <div className="Customize-lead">
                                            <div className="card-body">
                                                <div className="combobox" ref={wrapperRefUser} name="user">
                                                    <div className="combobox container-input">
                                                        <span className="fa fa-cubes" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                        <input type="text" placeholder={"search user to share"} onChange={(e) => searchValue(e)} />
                                                        <span className="button-icon ion-md-arrow-dropdown"></span>
                                                    </div>
                                                    <div className={`listbox d-${showUserPanel ? "block" : "none"}`}>
                                                        <ul>
                                                            {(
                                                                users.map((item) => renderInfoInDroplist(item, addCreateList))
                                                            )}
                                                        </ul>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                createList.length > 0 && (



                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">

                                            {(provided, snapshot) => (
                                                <div className="w-100" {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                    <h5>Has been shared for</h5>
                                                    <small id="nameHelpList" className="form-text text-muted">Click and drag to change position. This order will be the data sharing order</small>
                                                    {
                                                        createList.map((item, index) => (
                                                            <Draggable key={item.id} draggableId={item.id + "-" + index} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div className="container" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )} >
                                                                        <UserShareRow key={item.id} data={item} setMinuteUser={setMinuteUser} remove={removeFromCreateList} />
                                                                    </div>


                                                                )}

                                                            </Draggable>
                                                        ))
                                                    }





                                                </div>

                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                )
                            }
                            {
                                selectedProject !== null && (
                                    <CardFooter>
                                        <Button color={'success'} onClick={() => saveData()}>Save</Button>
                                    </CardFooter>
                                )
                            }
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )

}

export default CreateShareData;