import React, { Component } from 'react';
import Navbar from "../Navbar";
import * as methodTypes from "../../Constants/method.constants";
import HookAPI from "../../utility/HookAPI";
import message from "@davistran86/notification"

export default class EditSharaData extends Component {
    constructor() {
        super();
        this.state = {
            user: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false
            },
            inputValue: "",
            role: -1,
            editid: -1,
            isCreated: false,
            listProject: [],
            selectedProject: {
                selected: null,
                list: [],
                isLoading: false,
                toggleSelect: false,
            }
        }
        this.wrapperRef = [];
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    setupToggleOff = () => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (["user", "selectedProject"].some(el => el === object[0])) {
                object[1].toggleSelect = false;
                this.setState({
                    object: {
                        ...object[1],
                    }
                });
            }
        })
    }

    setupToggle = (data, toggle) => {
        let myState = Object.entries(this.state);

        myState.map((object) => {
            if (typeof object[1] === "object") {
                if (object[0] === data) {
                    object[1].toggleSelect = toggle;

                    this.setState({
                        object: {
                            ...object[1]
                        }
                    })
                } else {
                    object[1].toggleSelect = false;
                    this.setState({
                        object: {
                            ...object[1],
                        }
                    });
                }

            }
        })
    }

    renderInfoInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id, e)}>
                <div>
                    <span className="name">{data.name ? data.name : data.email}</span>
                    <span className="id-name">{data.facebook_name ? data.facebook_name : (data.email ? data.email : data.id)}</span>

                </div>

            </li>
        )
    }
    renderInfoLSLInDroplist = (data, ef) => {
        return (
            <li key={data.id} onClick={(e) => ef(data.id, e)}>
                <div>
                    <span className="name">{data.lslName ? data.lslName : "Unknown"}</span>

                </div>

            </li>
        )
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.find(el => (el != null && el.contains(event.target)))) {
            this.setupToggleOff();
        }

    }

    handleSearchValue = (e) => {
        const { user } = this.state;
        this.setState({
            inputValue: e.target.value
        })
        if (e.target.value != null && e.target.value != "") {

            HookAPI(null, "/web/{userid}/search/" + e.target.value, methodTypes.GET).then((response) => {
                response = response.data;
                if (response.status === 200) {
                    this.setState({
                        user: {
                            selected: user.id,
                            list: response.data,
                            isLoading: false,
                            toggleSelect: true
                        }
                    })
                }
            });

            this.setState({
                user: {
                    selected: user.id,
                    list: user.list,
                    isLoading: user.isLoading,
                    toggleSelect: true
                }
            });
        } else {
            this.setState({
                user: {
                    selected: user.id,
                    list: user.list,
                    isLoading: user.isLoading,
                    toggleSelect: false
                }
            });
        }
    }

    handleChangeProjectValue = (e) => {
        const { selectedProject } = this.state;
        HookAPI("", "web/{userid}/List").then((res) => {
            let response = res.data;
            if (response.status === 200) {
                response.data.push({ id: -1, lslName: "Share all" })
                this.setState({
                    selectedProject: {
                        selected: selectedProject.id,
                        list: response.data,
                        isLoading: false,
                        toggleSelect: true
                    }
                })
            }
        })
    }

    handleInputRole = (e) => {
        let value = e.target.value;
        this.setState({
            role: value,
            isCreated: false
        })
    }

    selectedUser = (id) => {
        const { user } = this.state;
        this.setState({
            user: {
                selected: id,
                list: user.list,
                isLoading: user.isLoading,
                toggleSelect: false
            }
        });
    }

    selectProject = (id) => {
        const { selectedProject } = this.state;
        this.setState({
            selectedProject: {
                selected: id,
                list: selectedProject.list,
                isLoading: selectedProject.isLoading,
                toggleSelect: false
            },
            isCreated: false
        });
    }


    handleSubmitData = () => {
        const { user, role, selectedProject } = this.state;
        let dataPrepare = {};

        dataPrepare.shared_id = user.selected;
        dataPrepare.shared_role = parseInt(role);
        if (selectedProject.selected !== -1 && selectedProject.selected != null && selectedProject.selected) {

            dataPrepare.projectId = parseInt(selectedProject.selected);
        }
        if (role < 0) {
            message.error({ title: "Meai notification", description: "Role must equal 0 or bigger" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            });
            return;
        }
        this.setState({
            isCreated: true
        })
        HookAPI(dataPrepare, "datasharing/{userid}/edit/" + this.props.match.params.id, methodTypes.POST).then((response) => {
            this.setState({
                isCreated: false
            })
        });

    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        HookAPI("", "web/{userid}/List").then((res) => {
            let response = res.data;
            if (response.status === 200) {
                response.data.push({ id: -1, lslName: "Share all" })
                this.setState({
                    selectedProject: {
                        selected: null,
                        list: response.data,
                        isLoading: false,
                        toggleSelect: false
                    }
                })
            }
            HookAPI(null, "datasharing/{userid}/edit/" + this.props.match.params.id).then((response) => {
                if (response.data.status === 200) {
                    response = response.data;
                    this.setState({
                        user: {
                            selected: response.data.sharedUser.id,
                            list: [response.data.sharedUser],
                            isLoading: false,
                            toggleSelect: false
                        },
                        inputValue: "",
                        editid: response.data.id,
                        role: response.data.role,
                        selectedProject: {
                            selected: response.data.projectId,
                            list: this.state.selectedProject.list,
                            isLoading: false,
                            toggleSelect: false
                        }
                    })
                }
            })
        })

    }

    componentWillUnmountMount() {
        document.removeEventListener('mousedown', this.handleClickOutside);

    }

    removeAppName = () => {
        this.setState({
            app_name: false
        })
    }

    render() {
        const { user, inputValue, selectedProject } = this.state;
        console.log(selectedProject);
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />

                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            Add sharing user
                        </h3>

                    </div>

                    <div className="card card-init-css" >


                        <div className="card card-tab">
                            <h5 className="card-title">Setup</h5>
                            <div className="card-body" >
                                <span>Choose user</span>
                                <div className="combobox" ref={(node) => this.wrapperRef[0] = node} name="user">
                                    <div className="combobox container-input">
                                        <span className="fa fa-bar-chart" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                        <input type="text" placeholder={"Search by email or name"} value={user.selected != null ? (user.list.find(element => element.id === user.selected)).name : inputValue} className="test" onChange={this.handleSearchValue.bind(this)} disabled={true} />
                                        <span className="button-icon ion-md-arrow-dropdown"></span>
                                    </div>
                                    <div className={`listbox d-${user.toggleSelect ? "block" : "none"}`}>
                                        <ul>
                                            {user.list.length > 0 ? (
                                                user.list.map((account) => this.renderInfoInDroplist(account, this.selectedUser))
                                            ) :
                                                (
                                                    <div>
                                                        <div className="justify-content-between" style={{ textAlign: "center" }}>
                                                            {user.list.length === 0 ? (

                                                                <div className="" role="status">
                                                                    <span>No data</span>
                                                                </div>
                                                            ) : (
                                                                    <div className="spinner-grow spinner-grow-sm " role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </ul>

                                    </div>
                                </div>
                                {
                                    user.selected != null && (<>
                                        <div style={{ marginTop: "15px" }}>
                                            <hr className="my-1" />
                                            <span className="small-text">Choose share project data</span>

                                            <small id="nameHelp" className="form-text text-muted">If not choose any project. application auto share all data you have</small>
                                            <div className="Customize-lead">
                                                <div className="card-body">
                                                    <div className="combobox" ref={(node) => this.wrapperRef[1] = node} name="user">
                                                        <div className="combobox container-input">
                                                            <span className="fa fa-cubes" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                            <input type="text" placeholder={"Search by email or name"} value={selectedProject.selected != null ? (selectedProject.list.find(element => element.id === selectedProject.selected)).lslName : "Share all Data"} className="test" onClick={this.handleChangeProjectValue.bind(this)} readOnly={true} />
                                                            <span className="button-icon ion-md-arrow-dropdown" onClick={this.handleChangeProjectValue.bind(this)}></span>
                                                        </div>
                                                        <div className={`listbox d-${selectedProject.toggleSelect ? "block" : "none"}`}>
                                                            <ul>
                                                                {(
                                                                    selectedProject.list.map((account) => this.renderInfoLSLInDroplist(account, this.selectProject))
                                                                )}
                                                            </ul>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {
                                            selectedProject.selected != null && (
                                                <div style={{ marginTop: "15px" }}>
                                                    <hr className="my-1" />
                                                    <span className="small-text">Set role</span>
                                                    <div className="Customize-lead">
                                                        <div className="card-body">
                                                            <div className="combobox" name="role">
                                                                <div className="combobox container-input">
                                                                    <span className="fa fa-bar-chart" style={{ position: "relative", color: "rgb(19, 107, 245)", opacity: 1 }}></span>
                                                                    <input type="number" className="test" name="role" onKeyUp={this.handleInputRole.bind(this)} defaultValue={this.state.role ? this.state.role : ""} placeholder="Role must be a number" min="0" />
                                                                </div>

                                                                <small id="nameHelp" className="form-text text-muted">the larger the role, the more preferred</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (!this.state.isCreated) ? (<button className="button-cofirmsubmit" onClick={this.handleSubmitData.bind(this)}>


                                                            <span>&nbsp;Submit Edit &nbsp;<span className="ion-md-done-all"></span> </span>
                                                        </button>) : (
                                                                <button className="button-cofirmsubmit-disable" disabled={true}>
                                                                    <span>&nbsp;Submit Edit &nbsp;<span className="ion-md-done-all"></span> </span>
                                                                </button>)
                                                    }
                                                </div>


                                            )
                                        }
                                    </>
                                    )



                                }


                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}