import React, { Component } from 'react';
import * as methodTypes from '../../Constants/method.constants';
import HookAPI from "../../utility/HookAPI";
import Navbar from "../Navbar";
import { Form, Table } from "react-bootstrap";
import message from "@davistran86/notification"


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            user_email: "",
            user_name: "",
            user_join: "",
            user_time_active: "",
            user_month_active: "",
            bank_id: "",
            bank_name: "",
            isChangePassword: false,
            password: "",
            re_password: "",
            old_password: "",
            isWrongrePass: false,
            listInvited: [],
            totalMoney: 0
        }
    }

    handleSwitch = (e) => {
        this.setState({
            isChangePassword: !this.state.isChangePassword
        })
    }
    handleChangeState = (e, changeState) => {

        this.setState({
            [changeState]: e.target.value
        })
        if (e.target.name === "re_password" || e.target.name === "password") {
            if (this.state.password !== e.target.value) {
                this.setState({
                    isWrongrePass: true
                })
            } else {
                this.setState({
                    isWrongrePass: false
                })
            }
        }
    }

    submitData = () => {
        let dataPrepare = {};
        dataPrepare.user_name = this.state.user_name;
        dataPrepare.is_change_password = this.state.isChangePassword;

        if (this.state.bank_id != null || this.state.bank_name != null) {
            if (this.state.bank_id != null && this.state.bank_name != null) {
                dataPrepare.banking_id = this.state.bank_id;
                dataPrepare.banking_name = this.state.bank_name;
            } else {
                message.warning({ title: "Error when save profile", description: "Banking ID and Banking Name can not empty" });
                return;
            }
        }



        if (this.state.isChangePassword) {
            dataPrepare.old_password = this.state.old_password;
            dataPrepare.password = this.state.password;
        }

        HookAPI(dataPrepare, "users/self", methodTypes.POST);
    }



    componentWillMount() {
        HookAPI("", "users/self", methodTypes.GET).then((response) => {
            let data = response.data;
            if (data.status === 200) {
                let totalMoney = 0
                let userInfor = data.data.userInfor;
                let affiliateInfor = data.data.affiliateInfor;
                this.setState({
                    user_id: userInfor.id,
                    user_email: userInfor.email,
                    user_name: userInfor.name,
                    user_join: userInfor.createTime,
                    user_time_active: userInfor.activeTime,
                    user_month_active: userInfor.activeMonth,
                    bank_id: userInfor.bankId,
                    bank_name: userInfor.bankName,
                    password: "",
                    re_password: "",
                    old_password: "",
                    isWrongrePass: false,
                    listInvited: affiliateInfor
                })
                affiliateInfor.map(element => {
                    totalMoney += element.moneyRefund
                })
                this.setState({
                    totalMoney: totalMoney
                })
            }
        })
    }
    requestRefund = (e) => {
        HookAPI("", "web/{userid}/affiliate", methodTypes.POST);
    }

    render() {
        const { listInvited } = this.state
        return (
            <div id="content" className="p-4 p-md-5">
                <Navbar />

                <div className="container-content">
                    <div className="card-hover">
                        <h3 className="card-title component-header">
                            Edit Profile
                        </h3>
                    </div>
                    <div className="card card-init-css" >
                        <div className="card card-tab">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-3 px-md-1">
                                        <div className="form-group">
                                            <label>Your name</label>
                                            <input type="text" className="form-control" name="user_name" defaultValue={this.state.user_name} onChange={(event) => this.handleChangeState(event, "user_name")} />
                                        </div>
                                    </div>
                                    <div className="col-md-5 pr-md-1">
                                        <div className="form-group">
                                            <label>Your email</label>
                                            <input type="text" className="form-control email_user_control" defaultValue={this.state.user_email} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 pr-md-1">
                                        <div className="form-group">
                                            <label>Join time</label>
                                            <input type="text" className="form-control point_user_control" disabled defaultValue={this.state.user_join} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 px-md-1">
                                        <div className="form-group">
                                            <label>Month active</label>
                                            <input type="text" className="form-control" disabled defaultValue={this.state.user_month_active} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 pr-md-1">
                                        <div className="form-group">
                                            <label>Banking ID</label>
                                            <input type="text" className="form-control point_user_control" defaultValue={this.state.bank_id} onChange={(event) => this.handleChangeState(event, "bank_id")} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-md-1">
                                        <div className="form-group">
                                            <label>Banking Name</label>
                                            <input type="text" className="form-control" defaultValue={this.state.bank_name} onChange={(event) => this.handleChangeState(event, "bank_name")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Form.Check type="switch" id="custom-switch" label="Change password" onChange={this.handleSwitch.bind(this)} />
                                </div>
                                {
                                    this.state.isChangePassword && (
                                        <div>
                                            <div className="row">
                                                <div className="col-md-5 pr-md-1">
                                                    <div className="form-group">
                                                        <label>Enter old password</label>
                                                        <input type="password" className="form-control" name="old_password" value={this.state.old_password} onChange={(event) => this.handleChangeState(event, "old_password")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-5 pr-md-1">
                                                    <div className="form-group">
                                                        <label>Enter new password</label>
                                                        <input type="password" className="form-control" name="password" value={this.state.password} onChange={(event) => this.handleChangeState(event, "password")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-5 pr-md-1">
                                                    <div className="form-group">
                                                        <label>Re-enter new password</label>
                                                        <input type="password" className="form-control" name="re_password" value={this.state.re_password} style={this.state.isWrongrePass ? ({ borderBottom: "4px solid red" }) : (this.state.re_password !== "" ? { borderBottom: "4px solid green" } : {})} onChange={(event) => this.handleChangeState(event, "re_password")} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-fill btn-primary submit-action-edit" onClick={this.submitData}>Save</button>
                            </div>
                        </div>


                        <div className="card card-tab">
                            <div className="card-body">
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Invited Name</th>
                                            <th>Invited Email</th>
                                            <th>Invited</th>
                                            <th>Money Refund</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    {listInvited.length > 0 ? (
                                        <tbody>
                                            {listInvited.map(element =>
                                                (<tr key={element.id}>
                                                    <td>{element.id}</td>
                                                    <td>{element.invitedName}</td>
                                                    <td>{element.invitedEmail}</td>
                                                    <td>{element.numberInvitedInvite}</td>
                                                    <td>{element.moneyRefund}</td>
                                                </tr>)
                                            )}
                                        </tbody>
                                    ) : (<tbody>
                                        <tr><td colSpan="5">No data</td></tr>
                                    </tbody>)}
                                    <tfoot>
                                        <tr>
                                            <th colSpan="5">Total: {this.state.totalMoney}</th>
                                            <th><button onClick={this.requestRefund.bind(this)} className="btn btn-info">Refund</button></th>
                                        </tr>
                                        <tr>
                                            <th colSpan="5">Link affiliate:</th>
                                        </tr>
                                        <tr><th colSpan="5"><span>https://meai.vn/sign-up?inviteCode={this.state.user_id}</span></th></tr>
                                    </tfoot>

                                </Table>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

export default Profile;