import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import "./Sidebar.css";
import "./index.css";
import { isAdmin } from '../../Constants/Config.constants';


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            hideSidebar: false,
            width: window.innerWidth,
            height: window.innerHeight,
            arrShowSubMenu: []
        }
        this.activeRoute.bind(this);
    }
    activeRoute(routeName) {
        return this.props.location === routeName ? "active" : "";
    }

    handleClickDropDownMenu = (id, e) => {
        let { arrShowSubMenu } = this.state;
        arrShowSubMenu[id] = arrShowSubMenu[id] ? !arrShowSubMenu[id] : true;
        this.setState({
            arrShowSubMenu: arrShowSubMenu
        })
    }

    toggleCollapse = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    closeCollapse = () => {
        this.setState({
            collapseOpen: false
        });
    };

    updateDimensions = () => {
        if (window.innerWidth < 992) {
            this.setState({
                hideSidebar: true
            })
        } else {
            this.setState({
                hideSidebar: false
            })
        }
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        if (window.innerWidth < 992) {
            this.setState({
                hideSidebar: true
            })
        } else {
            this.setState({
                hideSidebar: false
            })
        }
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }


    render() {
        const { collapseOpen, hideSidebar, arrShowSubMenu } = this.state;
        const { listRoute } = this.props;
        return (
            <Router>
                <nav id="sidebar" className={collapseOpen ? "active" : ""} style={{ display: (hideSidebar ? "none" : "block") }}>
                    <h1><a href={`${(listRoute.find(item => item.role === "home")).path}`} className="logo">{collapseOpen ? "M." : "Meai"}</a></h1>
                    <ul className="list-unstyled components mb-5">
                        {listRoute.map((item) => (!item.isTesting || isAdmin()) && (
                            <li key={item.id} className={this.activeRoute(item.path)} className="subMenu" onClick={this.handleClickDropDownMenu.bind(this, item.id)}>
                                <a href={item.path} ><span className={`${item.icon}`} />{item.name}</a>
                                {item.routes && (
                                    <ul style={{ display: (arrShowSubMenu[item.id] ? "block" : "none") }} >
                                        {
                                            item.routes.map((element) => (
                                                <li key={element.id} className={this.activeRoute(item.path)}>
                                                    <a href={element.path}><span className={`${element.icon}`} />{element.name}</a>
                                                </li>
                                            ))

                                        }
                                    </ul>
                                )}
                            </li>
                        ))}
                        <li style={{ textAlign: "center", fontSize: `28px`, cursor: "pointer" }} onClick={this.toggleCollapse}>
                            <span className={`ion-md-arrow-${collapseOpen ? "dropright" : "dropleft"}`} ></span>
                        </li>
                    </ul>
                </nav>

            </Router>


        )
    }
}

export default Sidebar;