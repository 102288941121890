import React, { Component } from "react";
import message from "@davistran86/notification";
import HookAPI from "../../utility/HookAPI";
import * as methodTypes from "../../Constants/method.constants";
import Cookies from "js-cookie";
import { ROOT } from "../../Constants/Config.constants";

export default class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            rePassword: "",
            isWrongrePass: false,
            signUpSuccess: false,
        };
    }

    handleChange = (e) => {
        var changeState = e.target.name;
        this.setState({
            [changeState]: e.target.value
        })
        if(e.target.name === "rePassword" || e.target.name === "password"){
            if(this.state.password !== e.target.value){
                this.setState({
                    isWrongrePass:true
                })
            }else{
                this.setState({
                    isWrongrePass:false
                })
            }
        }
    }

    handleSubmit = (e) => {
        const { firstName, lastName, email, password, isWrongrePass} = this.state;
        if(password.length < 10){
            message.warning({title:"Error when create account",description:"Password must be longer than 10 characters"});
            return;
        }
        if(!isWrongrePass){
            let dataPrepare = Object();
            dataPrepare.userName = firstName + " " + lastName;
            dataPrepare.userEmail = email;
            dataPrepare.userPassword = password;
            let urlRequest = "users";
            let inviteCode = new URLSearchParams(this.props.location.search).get("inviteCode");

            if(inviteCode !== undefined){
                urlRequest = "users?inviteCode="+inviteCode
            }


            HookAPI(dataPrepare, urlRequest, methodTypes.POST).then((response) => {
                let data = response.data;
                if(data.success){

                    if(data.data.cookies !== undefined){
                        for(const [key, value] of Object.entries(data.data.cookies)){
                            Cookies.set(key,value,{ expires: 7, path: '/' });
                        }
                    }

                    this.setState({
                        signUpSuccess:true
                    })
                }else{
                    this.setState({
                        signUpSuccess:false
                    })
                }
            })

        }else{
            message.warning({title:"Create account failed",description:"your password and re-password are not the same!!!"});
            return;
        }
        
    }


    render() {
        if(this.state.signUpSuccess){
            window.location.replace(ROOT);
        }
        return (
            <form>
                <h3>Sign Up</h3>

                <div className="form-group">
                    <label>First name</label>
                    <input type="text" className="form-control" name="firstName" placeholder="First name" onChange={this.handleChange.bind(this)} />
                </div>

                <div className="form-group">
                    <label>Last name</label>
                    <input type="text" className="form-control" name="lastName" placeholder="Last name" onChange={this.handleChange.bind(this)}/>
                </div>

                <div className="form-group">
                    <label>Email address</label>
                    <input type="email" className="form-control" name="email" placeholder="Enter email" onChange={this.handleChange.bind(this)}/>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" name="password" placeholder="Enter password" onChange={this.handleChange.bind(this)}/>
                </div>
                <div className="form-group" style={this.state.isWrongrePass ? ({borderBottom:"4px solid red"}):(this.state.rePassword !== "" ? {borderBottom:"4px solid green"}:{})}>
                    <label>Re-password</label>
                    <input type="password" className="form-control" name="rePassword" placeholder="Re-password" onChange={this.handleChange.bind(this)}/>
                </div>

                <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit.bind(this)}>Sign Up</button>
                <p className="forgot-password text-right">
                    Already registered <a href="./sign-in">sign in?</a>
                </p>
            </form>
        );
    }
}