import React, { Component } from 'react';
import Sidebar from "./Components/Sidebar";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import CreateCommentSetting from './Components/CreateCommentSetting';
import ListCommentSetting from './Components/ListCommentSetting';
import EditCommentSetting from './Components/EditCommentSetting';
import Create from './Components/Create';
import Edit from './Components/Edit';
import ListCreated from "./Components/ListCreated";
import PhoneCollection from "./Components/ListPhone";
import CreateShareData from "./Components/ShareData/create";
import EditSharaData from "./Components/ShareData/edit";
import ListSharedData from "./Components/ShareData/list";
import ListFliGData from "./Components/ShareData/listData";
import ListFliGShareData from "./Components/ShareData/listShareData";
import Profile from "./Components/Profile";
import CreatePageManage from "./Components/PageManage/create";
import Maintenance from "./Components/ServerMaintenance";
import './App.css';
import Cookies from 'js-cookie';
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import SubmitEmail from "./Components/ForgotPassword/SubmitEmail";
import SubmitPassword from "./Components/ForgotPassword/SubmitPassword";
import Privacy from "./Components/PrivactyPolicy";
import About from './Components/About';
import HookAPI from './utility/HookAPI';
import PageManageShare from './Components/PageManage/share';
import ListPageActive from './Components/PageManage/pageShared';
const listRoute = [
  {
    id: 1,
    name: "Home",
    path: "/",
    role: "home",
    icon: "ion-md-home",
    exact: true,
    component: Dashboard
  },
  {
    id: 2,
    name: "Create",
    path: "/Create",
    role: "create",
    icon: "ion-md-add",
    exact: false,
    component: Create
  },
  {
    id: 3,
    name: "List Created",
    path: "/List",
    role: "list",
    icon: "ion-md-list",
    exact: false,
    component: ListCreated
  },
  {
    id: 4,
    name: "Edit",
    path: "/Edit",
    role: "edit",
    icon: "ion-md-create",
    exact: false,
    component: Edit
  },


  {
    id: 5,
    name: "Profile",
    path: "/Profile",
    role: "Profile",
    icon: "ion-md-person",
    exact: false,
    component: Profile
  },
  {
    id: 6,
    name: "CommentSetting",
    path: "/CommentSetting",
    role: "CommentSetting",
    icon: "ion-md-person",
    exact: false,
    component: CreateCommentSetting
  },
  {
    id: 7,
    name: "List Comment Setting",
    path: "/ListComment",
    role: "List",
    icon: "ion-md-list",
    exact: false,
    component: ListCommentSetting
  },
  {
    id: 8,
    name: "Edit Comment Setting",
    path: "/EditComment/:id",
    role: "Edit",
    icon: "ion-md-create",
    exact: false,
    component: EditCommentSetting
  },
  {
    id: 9,
    name: "Phone Collection",
    path: "/PhoneCollection",
    role: "Edit",
    icon: "ion-md-create",
    exact: false,
    component: PhoneCollection
  },
  {
    id: 10,
    name: "Data Sharing",
    path: "/DataSharing",
    role: "Create",
    icon: "ion-md-create",
    exact: false,
    component: CreateShareData
  },
  {
    id: 11,
    name: "Edit Data Sharing",
    path: "/EditDataSharing/:id",
    role: "Edit",
    icon: "ion-md-create",
    exact: false,
    component: EditSharaData
  },
  {
    id: 12,
    name: "List Data Sharing",
    path: "/ListDataSharing",
    role: "list",
    icon: "ion-md-list",
    exact: false,
    component: ListSharedData
  },
  {
    id: 13,
    name: "Data Collection",
    path: "/FliGData",
    role: "list",
    icon: "ion-md-list",
    exact: false,
    component: ListFliGData
  },
  {
    id: 14,
    name: "Data Share Collection",
    path: "/FliGShareData",
    role: "list",
    icon: "ion-md-list",
    exact: false,
    component: ListFliGShareData
  },
  {
    id: 15,
    name: "Manage Messenger",
    path: "/Pagemanager",
    role: "list",
    icon: "ion-md-list",
    exact: true,
    component: CreatePageManage
  },
  {
    id: 16,
    name: "List Active",
    path: "/Pagemanager/List",
    role: "list",
    icon: "ion-md-list",
    exact: true,
    component: ListPageActive
  },
  {
    id: 16,
    name: "Edit Messenger",
    path: "/Pagemanager/:id",
    role: "list",
    icon: "ion-md-list",
    exact: false,
    component: PageManageShare
  }

];

const listRoute_show = [
  {
    id: 1,
    name: "Home",
    path: "/",
    role: "home",
    icon: "ion-md-home",
  },
  {
    id: 2,
    name: "Manage FliG",
    path: "#",
    role: "manage",
    icon: "ion-md-albums",
    routes: [
      {
        id: 21,
        name: "Create",
        path: "/Create",
        icon: "ion-md-add",
      },
      {
        id: 22,
        name: "List Created",
        path: "/List",
        icon: "ion-md-list",
      },
    ]
  },
  {
    id: 3,
    name: "Manage Comment",
    path: "#",
    role: "manage",
    icon: "ion-md-albums",
    routes: [
      {
        id: 31,
        name: "Create",
        path: "/CommentSetting",
        icon: "ion-md-add",
      },
      {
        id: 32,
        name: "List Created",
        path: "/ListComment",
        icon: "ion-md-list",
      },
      {
        id: 33,
        name: "Phone Collection",
        path: "/PhoneCollection",
        icon: "ion-md-list"
      }
    ]
  },
  {
    id: 4,
    name: "Manage Data",
    path: "#",
    role: "manage",
    icon: "ion-md-albums",
    routes: [
      {
        id: 41,
        name: "Share Data",
        path: "/DataSharing",
        icon: "ion-md-add",
      },
      {
        id: 42,
        name: "List Created",
        path: "/ListDataSharing",
        icon: "ion-md-list",
      },
      {
        id: 43,
        name: "Data Collection",
        path: "/FliGData",
        icon: "ion-md-list"
      },
      {
        id: 44,
        name: "Data Share",
        path: "/FliGShareData",
        icon: "ion-md-list"
      }
    ]
  },

  {
    id: 6,
    name: "Messenger Manage",
    path: "#",
    icon: "ion-md-list",
    isTesting:false,
    routes: [

      {
        id: 61,
        name: "Messenger Manager",
        path: "/Pagemanager",
        icon: "ion-md-list"
      },
      {
        id: 62,
        name: "List Active",
        path: "/Pagemanager/List",
        icon: "ion-md-list"
      },
    ]
  },
  {
    id: 5,
    name: "Profile",
    path: "/Profile",
    icon: "ion-md-person",
  }
];




class App extends Component {


  componentDidMount() {
    HookAPI("", "users/checking");
  }


  render() {
    let isUpgrade = false;
    if (Cookies.get("_uid") === "e658b9b8-fa56-4319-9089-cfad837b0a98") {
      isUpgrade = false;
    }
    var currentLocation = window.location.pathname;

    if (isUpgrade) {
      return (
        <Router>
          <Switch>
            <Route path="/" component={Maintenance} />
            <Route exact path='/privacy' component={Privacy} />
          </Switch>
        </Router>
      )
    } else {
      if (currentLocation.indexOf("privacy") > 0) {
        return (
          <Router>
            <Switch>

              <Route exact path='/privacy' component={Privacy} />
            </Switch>
          </Router>
        )
      } else if (Cookies.get("_tkl") !== undefined && Cookies.get("_uid") !== undefined) {



        return (

          <div className="wrapper d-flex align-items-stretch">

            <Sidebar listRoute={listRoute_show} location={currentLocation} />


            <Router>
              <Switch>

                {listRoute.map((item, index) => (
                  <Route key={index} path={item.path} component={item.component} exact={item.exact} />



                ))}

              </Switch>
            </Router>
          </div>
        );
      } else {
        if (currentLocation.indexOf("forgot") > 0 || currentLocation.indexOf("resetpassword") > 0) {
          return (
            <Router>
              <Route exact path='/' component={About} />
              <Route exact path='/privacy' component={Privacy} />
              <Switch>
                <div className="App">
                  <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                    <div className="container">
                      <Link className="navbar-brand" to={"/sign-in"}>Meai.vn</Link>
                      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav ml-auto">
                          <li className="nav-item">
                            <Link className="nav-link" to={"/sign-in"}>Login</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={"/sign-up"}>Sign up</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>

                  <div className="auth-wrapper">
                    <div className="auth-inner">
                      <Route exact path='/forgot' component={SubmitEmail} />
                      <Route path="/resetpassword/reset" component={SubmitPassword} />
                      <Route path="/sign-up" component={SignUp} />
                      <Route path="/sign-in" component={Login} />
                    </div>
                  </div>
                </div>
              </Switch>
            </Router>
          )
        } else
          return (
            <Router>
              {
                (window.location.pathname !== "/" && window.location.pathname !== "/sign-up" && window.location.pathname !== "/sign-in") ? (<Redirect to={"/"} />) : ("")
              }
              <Switch>
                <Route exact path='/' component={About} />
                <Route exact path='/privacy' component={Privacy} />
                <div className="App">
                  <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                    <div className="container">
                      <Link className="navbar-brand" to={"/"}>Meai.vn</Link>
                      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav ml-auto">
                          <li className="nav-item">
                            <Link className="nav-link" to={"/sign-in"}>Login</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={"/sign-up"}>Sign up</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>

                  <div className="auth-wrapper">
                    <div className="auth-inner">
                      <Route path="/sign-in" component={Login} />
                      <Route path="/sign-up" component={SignUp} />
                    </div>
                  </div>
                </div>
              </Switch>
            </Router>
          )

      }
    }


  }




}

export default App;
