import React, { Component } from "react";
import { Navbar, Nav, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Card from "reactstrap/lib/Card";
import logomeai from "../../Image/apple-icon-72x72.png";
import logoFacebook from "../../Image/logo-facebook.png";
import compileLine from "../../Image/complete-line.svg";
import thickCompileLine from "../../Image/thick-line-horizontal.svg"
import googleSheet from "../../Image/logo-sheet.png";
import emailLogo from "../../Image/logo-google-gmail.png";
import ranking from "../../Image/Ranking.png";
import demoCreate from "../../Image/demoCreate.PNG"
import phoneImage_1 from "../../Image/230x0w_1.png";
import passiveIncome from "../../Image/passive-income.png";
import phoneImage_2 from "../../Image/230x0w.webp";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class About extends Component {




    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{ marginBottom: "unset" }}>
                    <Navbar.Brand href="/"><img src={logomeai} width="35px" height="35px" alt="meai.vn"></img></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <Nav.Link href="/sign-in">Log in</Nav.Link>
                            <Nav.Link href="sign-up"> Sign up</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Container fluid >
                    <Row style={{ height: "700px", backgroundColor: "#DEF7F7", paddingTop: "50px" }}>
                        <Container>
                            <Row >
                                <Col md style={{ fontFamily: "Open Sans, sans-serif", fontSize: "40px", fontWeight: "800", letterSpacing: "-1px", lineHeight: "45px", textAlign: "left" }}>
                                    <span>
                                        <h2 style={{ fontSize: "72px" }}>meai.vn</h2>

                                    </span>
                                    <span>
                                        <h6 style={{ fontSize: "24px" }}> Connect your facebook data with google</h6>
                                        <p>Store all data in to spreadsheet google</p>
                                    </span>
                                    <p><a href="/sign-in" className="btn btn-outline-info">Login with your account</a></p>
                                    <p><a href="/sign-up" className="btn btn-primary">Create a new account</a></p>
                                </Col>
                                <Col>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/AKURNKGyNDA?autoplay=1&mute=1" frameBorder="0" allow="autoplay;encrypted-media" allowFullScreen></iframe>
                                </Col>

                            </Row>

                        </Container>
                    </Row>
                </Container>
                <Container className="mt-3 ">
                    <Row>
                        <Col md={4}>
                            <Card className="shadow-lg p-3 mb-5 bg-white rounded " style={{ minHeight: "420px" }}>
                                <Container>

                                    <Row className="text-center justify-content-center m-3">

                                        <img style={{ width: "25%" }} src={logoFacebook} alt="Card image cap" />
                                        <img style={{ width: "25%" }} src={compileLine} alt="Card image cap" />
                                    </Row>
                                    <Row className="text-center justify-content-center">
                                        <h3>Integrate</h3>
                                    </Row>
                                    <Row className="m-3 p-3">
                                        Link your facebook marketing ads for few click, and they can share data
                                    </Row>
                                </Container>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="shadow-lg p-3 mb-5 bg-white rounded" style={{ minHeight: "420px" }}>
                                <Container>

                                    <Row className="text-center justify-content-center m-3">

                                        <img style={{ width: "25%" }} src={logoFacebook} alt="Card image cap" />
                                        <img style={{ width: "15%" }} src={thickCompileLine} alt="Card image cap" />
                                        <img style={{ width: "25%" }} src={logomeai} alt="Card image cap" />
                                        <img style={{ width: "25%" }} src={compileLine} alt="Card image cap" />
                                    </Row>
                                    <Row className="text-center justify-content-center">
                                        <h3>Connected</h3>
                                    </Row>
                                    <Row className="m-3 p-3">
                                        With Meai, you can connect and manage the flow of data
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="shadow-lg p-3 mb-5 bg-white rounded" style={{ minHeight: "420px" }}>
                                <Container>

                                    <Row className="text-center justify-content-center m-3">

                                        <img style={{ width: "25%" }} src={compileLine} alt="Card image cap" />
                                        <img style={{ width: "25%" }} src={googleSheet} alt="Card image cap" />
                                        <i className="fa fa-chevron-circle-right m-auto" aria-hidden="true"></i>
                                        <img style={{ width: "25%" }} src={emailLogo} alt="Card image cap" />
                                    </Row>
                                    <Row className="text-center justify-content-center">
                                        <h3>Stored and automated</h3>
                                    </Row>
                                    <Row className="m-3 p-3">
                                        <p>After use <strong> MEAI</strong> to link between facebook marketing ads and google spreadsheet, data from facebook ads will automatically store in spreadsheet, and this can send email if you allow it</p>
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="mt-3 p-5" style={{ background: "#DEF7F7" }}>
                    <Row className="ml-5">
                        <Col md={8}>
                            <h2><strong>Meai share data for whom you need to share, with MEAI's data sharing function</strong></h2>
                            <h5 className="text-muted">share and receive data from every facebook ads</h5>
                            <hr />
                            <p>
                                You can <strong>receive</strong> or <strong>share</strong> data from facebook friend or another meai's user automatically and realtime.
                            </p>
                        </Col>
                        <Col md={4} style={{ overflow: "hidden" }}>
                            <img src={ranking} />
                        </Col>
                    </Row>
                </Container>
                <Container className="p-5">
                    <Row>
                        <Col md={6} className="m-auto">
                            <h2>Anyone can build workflows for data with a few clicks</h2>
                            <a href={`https://www.youtube.com/watch?v=AKURNKGyNDA`}><Button style={{ background: "black", borderColor: "black" }}><i className="fa fa-play-circle"></i> See Demo here</Button></a>
                        </Col>
                        <Col md={6}>
                            <img src={demoCreate} />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="mt-3 p-5" style={{ background: "#DEF7F7" }}>
                    <Container>
                        <Row className="ml-5">
                            <Col md={6}>
                                <h2><strong>Want to see your realtime data every where ?</strong></h2>
                                {/* <h5 className="text-muted">share and receive data from every facebook ads</h5> */}
                                <a href={`https://apps.apple.com/us/app/meai-fblead/id1546982726`}><Button style={{ background: "black", borderColor: "black" }}><i className="fa fa-apple"></i> Download iOS app here!</Button></a> &nbsp;
                                <a href={`https://play.google.com/store/apps/details?id=com.meia.app`}><Button style={{ background: "black", borderColor: "black" }}><i className="fa fa-android"></i> Download Android app here!</Button></a>
                            </Col>
                            <Col md={6} className="shadow p-3 mb-5 bg-white rounded">
                                <img src={phoneImage_1} style={{ width: "50%", borderRadius: "10px 0 0 10px" }} />
                                <img src={phoneImage_2} style={{ width: "50%", borderRadius: "0 10px 10px 0" }} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="p-5">
                    <Row>
                        <Col md={6} className="m-auto">
                            <h2>You want a passive income from <strong>MEAI</strong></h2>
                            <Col>
                                <h5 className="text-muted">Passive income, which is income that you do not need to work too much or do not need to work to still have income.</h5>
                                <hr />
                                <h5><strong>GUIDE</strong></h5>
                                <p>Step 1: <Link to={`sign-up`}>sign up</Link> MEAI</p>
                                <p>Step 2: login with your account</p>
                                <p>Step 3: go to profile page</p>
                                <p>Step 4: get link in the end of page and share it</p>
                                <p><strong>Important step</strong>: Take a rest and get the money</p>
                                <p>Use your invite code in Profile page, and share it for any one. <Link to={`sign-up`}>Get invite link here!</Link></p>
                            </Col>
                        </Col>
                        <Col md={6} className="my-auto">
                            <img src={passiveIncome} className="shadow p-3 mb-5 bg-white rounded" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="mt-3 p-5" style={{ background: "#DEF7F7" }}>
                    <Container>
                        <Row className="ml-5">
                            <Col md={12} className="text-center">
                                <h1><strong>Sign up free and.. goodbye your busywork</strong></h1>
                                {/* <h5 className="text-muted">share and receive data from every facebook ads</h5> */}
                                <Link to={`/sign-up`}><button className="btn btn-dark-mode">Sign up now</button></Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container className="my-3">
                    <Row>
                        <Col md={8}>
                            <div className="d-flex" style={{ margin: "auto 0" }}>
                                <span >Follow us &nbsp;</span>
                            </div>
                            <div>
                                <ListGroup horizontal>
                                    <a href={`https://www.facebook.com/groups/meai.vn`}><ListGroup.Item className="rounded rounded-circle mr-3" style={{ background: "#999999", color: "white", padding: "6px 13px" }}><i className='fa fa-facebook' style={{ fontSize: "14px" }}></i></ListGroup.Item></a>
                                    <a><ListGroup.Item className="rounded rounded-circle mr-3" style={{ background: "#999999", color: "white", padding: "6px 13px" }}><i className='fa fa-linkedin' style={{ fontSize: "14px" }}></i></ListGroup.Item></a>
                                    <a><ListGroup.Item className="rounded rounded-circle mr-3" style={{ background: "#999999", color: "white", padding: "6px 13px" }}><i className='fa fa-youtube-play' style={{ fontSize: "14px" }}></i></ListGroup.Item></a>
                                    <a><ListGroup.Item className="rounded rounded-circle" style={{ background: "#999999", color: "white", padding: "6px 13px" }}><i className='fa fa-twitter' style={{ fontSize: "14px" }}></i></ListGroup.Item></a>
                                </ListGroup>
                            </div>
                        </Col>
                        <Col md={4} style={{ direction: "rtl" }}>
                            <ListGroup horizontal>
                                <a href={`https://www.youtube.com/watch?v=AKURNKGyNDA`}><ListGroup.Item className={`text-muted mr-3`} style={{ border: "unset" }}>Help</ListGroup.Item></a>

                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <Container className="my-5" style={{ minHeight: "100px" }}>
                    <Row>
                        <Col md={8}>
                            <p><Link to={`/sign-in`}><span className={`meai`}><strong>MEAI.VN</strong></span></Link> &nbsp;
                            <span>Make life easier</span></p>
                        </Col>
                        <Col md={4}>
                            <a href={`https://faceseo.vn`} style={{ direction: "ltr" }}><span><span><i className={`fa fa-copyright`}></i> FaceseoVN</span>  </span></a>

                            <span className={`mx-3`}><Link to={`/privacy`}>Privacy </Link></span>
                        </Col>
                    </Row>
                </Container>
            </div>
        );


    }
}